import { APP_ROUTE_WATCHLIST, BucketErrors } from '@aminsights/shared';
import { Dropdown } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import Button from '@/components/Button';
import Tooltip from '@/components/Tooltip';
import { APP_ACTIONS } from '@/constants';
import { STATIC_DATA_ADD_BUCKET_MODAL } from '@/constants/modals-static-data';
import { useAppContext } from '@/context/AppContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useCreateNewBucket } from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import BucketDefault from '@/partials/Bucket/Default';
import BucketSkeleton from '@/partials/Bucket/Default/Skeleton';
import EmptyBucketState from '@/partials/ManageBuckets/EmptyBucketState';
import AddEntityWithBenchmarkModal from '@/partials/Modal/AddEntityWithBenchmarkModal';
import NestedDrawer from '@/partials/NestedDrawer';
import SectionBackButton from '@/partials/Sections/SectionBackButton';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { toCamelCase } from '@/utils/toCamelCase';

import BasePageWithMetadata from '../../BasePageWithMetadata';

const Buckets: React.FCWithChild = () => {
  const history = useHistory();
  const location = useLocation();
  const appContext = useAppContext();
  const createNewBucket = useCreateNewBucket();
  const { data, isLoading } = useCurrentWatchlist();
  const [highlightedBucket, setHighlightedBucket] = useState<string | null>(
    null,
  );
  const [isAddNewBucketModalOpen, setIsAddNewBucketModalOpen] = useState(false);
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location?.search]);

  const bucketsTableRef = useRef<HTMLDivElement | null>(null);

  const renderBuckets =
    !data?.buckets || isLoading
      ? Array.from(new Array(6), (_, i) => (
          <div key={i} className="mr-4">
            <div data-test-id={`buckets${i}`} className="w-[320px] h-[490px]">
              <BucketSkeleton loading={true} />
            </div>
          </div>
        ))
      : data.buckets?.map((bucket, index) => (
          <div
            data-test-id={toCamelCase(`bucket${bucket.name}`)}
            key={bucket.id}
            className="mr-4"
          >
            <div className="w-[320px] h-[490px]">
              <BucketDefault
                id={bucket.id}
                order={index}
                name={bucket.name}
                index={bucket.index}
                funds={bucket.funds}
                sector={bucket.sector}
                active={highlightedBucket === bucket.id}
              />
            </div>
          </div>
        ));

  const menuItems = [
    {
      label: 'Import funds/trusts',
      key: 'ImportFunds',
      onClick: () => history.push(`/${APP_ROUTE_WATCHLIST}/import-funds`),
    },
  ];

  const handleAddNewBucketModalOpen = () => {
    setIsAddNewBucketModalOpen(prev => !prev);
  };

  const onSaveClick = async (
    name: string,
    benchmarkId?: string,
    sectorId?: string,
  ) => {
    const benchmarkName =
      benchmarkOptionsResponse?.find(b => b.secId === benchmarkId)?.name ?? '';
    await createNewBucket.mutateAsync({
      name,
      index: benchmarkName,
      indexSecId: benchmarkId || '',
      sector: sectorId,
      funds: [],
    });
  };

  useEffect(() => {
    if (searchParams.get('error') === BucketErrors.BUCKET_NONEXISTENT) {
      appContext.dispatch({
        type: APP_ACTIONS.SET_ERROR_MESSAGE,
        payload: 'Bucket does not exist',
      });
    }
    if (searchParams.get('current')) {
      setHighlightedBucket(searchParams.get('current'));
    }
  }, [searchParams]);

  useEffect(() => {
    if (highlightedBucket) {
      const timeout = setTimeout(() => {
        setHighlightedBucket(null);
        searchParams.delete('current');
        history.replace({ search: searchParams.toString() });
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [highlightedBucket]);

  const scrollOnBucketAdd = () => {
    const scrollableTable = bucketsTableRef.current;
    if (scrollableTable) {
      const maxScrollWidth = scrollableTable.scrollWidth;
      if (maxScrollWidth > 0) {
        setTimeout(() => {
          scrollableTable.scrollTo({
            left: maxScrollWidth,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  };

  return (
    <BasePageWithMetadata title={'Manage Buckets'}>
      <div data-test-id={`${STATIC_DATA_ADD_BUCKET_MODAL.entityName}`}>
        <AddEntityWithBenchmarkModal
          entities={data?.buckets ? data.buckets : []}
          isVisible={isAddNewBucketModalOpen}
          toggleModal={handleAddNewBucketModalOpen}
          onSaveClick={onSaveClick}
          staticModalData={STATIC_DATA_ADD_BUCKET_MODAL}
          scrollOnBucketAdd={scrollOnBucketAdd}
        />
      </div>
      <SectionBackButton previousLabel={'Back'} />
      <section className="h-auto flex flex-col lg:p-4">
        <div className="flex-1 flex flex-col shadow-[0px_3px_6px_@boxShadow] pt-4 pb-0 px-4 bg-white lg:rounded-lg">
          <div className="flex items-center mb-3.5 md:mb-3">
            <h2
              data-test-id="Buckets"
              className="text-xl font-bold text-darkest m-0"
            >
              Buckets{' '}
              <span className="text-xl font-normal text-neutral">
                ({data?.buckets.length ?? 0})
              </span>
            </h2>
            <div className="my-2">
              <Tooltip
                title="Maximum of 11 funds/trusts per bucket."
                placement="bottom"
                color="#3E414B"
                visible={tooltipVisible}
                onOpenChange={setTooltipVisible}
                arrow={{ pointAtCenter: true }}
              >
                <InfoIcon
                  className={cx('icon text-neutral mx-2', {
                    'text-neutral-100': tooltipVisible,
                  })}
                />
              </Tooltip>
            </div>

            <div className="flex ml-auto ">
              <Button
                type="primary"
                onClick={() => {
                  setIsAddNewBucketModalOpen(true);
                }}
                data-test-id="bucketAddButton"
                className="h-10"
              >
                <AddIcon className="icon text-md" />
                <span className="hidden text-sm font-medium md:block landscape:block">
                  Add Bucket
                </span>
              </Button>
              {/* TODO: make a single component for both dropDownMeatBallMenu */}
              <div data-test-id="dropDownMeatBallMenu" className="m-auto">
                <Dropdown
                  className="ml-4 p-2 h-8 rounded-full border border-light cursor-pointer fill-[#8C92B1] hover:bg-[#e6e9ee] hover:shadow-md transition-shadow duration-300"
                  menu={{ items: isMobile ? [] : menuItems }}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <MeatballMenu
                    onClick={() => isMobile && setIsNestedDrawerVisible(true)}
                  />
                </Dropdown>
                {isMobile && (
                  <NestedDrawer
                    menuItems={menuItems}
                    visible={isNestedDrawerVisible}
                    onClose={() => setIsNestedDrawerVisible(false)}
                    title="Select"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="h-full flex flex-col">
            <p className="text-neutral-700 mb-6 md:mb-4 text-sm font-normal">
              You can move funds/trusts between buckets and move the order of
              buckets.
            </p>

            <div>
              <div
                className="w-full flex overflow-auto h-auto"
                ref={bucketsTableRef}
              >
                {renderBuckets && renderBuckets.length ? (
                  renderBuckets
                ) : (
                  <EmptyBucketState />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default Buckets;
