import { isInvestmentTrust } from '@aminsights/shared';
import cx from 'classnames';
import React, { useMemo } from 'react';

import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import AbsoluteChart from '@/partials/Charts/absolute/AbsoluteChart';
import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import RelativeChart from '@/partials/Charts/relative/RelativeChart';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';

import style from './style.module.less';

export const FundCharts: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();

  const matchingBenchmark = benchmarkOptionsResponse?.find(
    b => b.secId === state?.fund?.performanceBenchmarkId,
  )?.name;

  const AbsoluteChartComponent = useMemo(() => {
    return (
      state?.fund?.shareClassDetails.isin && (
        <AbsoluteChart
          isins={
            state?.fund?.shareClassDetails.isin
              ? [state?.fund?.shareClassDetails.isin]
              : []
          }
          showFundLinks={false}
          sectorId={state?.fund?.iaSectorId}
          benchmarkId={state?.fund?.performanceBenchmarkId}
          hasInvestmentTrust={isInvestmentTrust(state?.fund?.legalStructure)}
        />
      )
    );
  }, [
    state?.fund?.shareClassDetails.isin,
    state?.fund?.performanceBenchmarkId,
  ]);

  const RelativeChartComponent = useMemo(() => {
    return (
      state?.fund?.shareClassDetails.isin && (
        <RelativeChart
          showFundLinks={false}
          isins={[state?.fund?.shareClassDetails.isin]}
          benchmarkId={state?.fund?.performanceBenchmarkId}
          hasInvestmentTrust={isInvestmentTrust(state?.fund?.legalStructure)}
        />
      )
    );
  }, [
    state?.fund?.shareClassDetails.isin,
    state?.fund?.performanceBenchmarkId,
  ]);

  return (
    <div
      className={cx(
        'grid gap-4',
        state?.fund?.performanceBenchmarkId ? 'grid-cols-2' : 'grid-cols-1',
      )}
    >
      <div className="col-span-2 lg:col-span-1">
        <div
          className={cx('p-4 bg-white lg:rounded-lg', style.chartContainer)}
          data-test-id="fundDetailsPerfRiskTabAbsoluteChart"
        >
          <AbsoluteChartTitle />
          {AbsoluteChartComponent}
        </div>
      </div>
      {state?.fund?.performanceBenchmarkId && (
        <div className="col-span-2 lg:col-span-1">
          <div
            className={cx('p-4 bg-white lg:rounded-lg', style.chartContainer)}
            data-test-id="fundDetailsPerfRiskTabRelativeChart"
          >
            <RelativeChartTitle benchmarkName={matchingBenchmark} />
            {RelativeChartComponent}
          </div>
        </div>
      )}
    </div>
  );
};
