import React, { useCallback } from 'react';

import { ChangePasswordFormProps } from '@/constants';
import { UpdatePasswordForm } from '@/partials/Forms/Settings';

import { useProvideSecurity } from './useProvideSecurity';

const Security: React.FCWithChild = () => {
  const { success, submitting, error, patchChangePassword } =
    useProvideSecurity();

  const onSubmit = useCallback((values: ChangePasswordFormProps) => {
    const formData = {
      newPassword: values.newPassword,
      currentPassword: values.currentPassword,
    };
    patchChangePassword(formData);
  }, []);

  return (
    <div className="pt-5 pl-5 pr-5 pb-10 flex flex-row justify-center">
      <UpdatePasswordForm
        submitting={submitting}
        onSubmit={onSubmit}
        error={error}
        success={success}
      />
    </div>
  );
};

export default Security;
