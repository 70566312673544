import {
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  MsTimePeriod,
  PLATFORMS_FILTERS,
} from '@aminsights/shared';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Accordion } from '@/components';
import { Panel } from '@/components/Accordion';
import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';
import lengthOrEmpty from '@/pages/app/Explore/utils/lengthOrEmpty';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import FullPageModal from '@/partials/Modal/FullPageModal';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { useExploreDefaultFiltersQuery } from '../context';
import { countBondsFilters } from '../hooks/bondsSubState';
import { countEquitiesFilters } from '../hooks/equitiesSubState';
import { countEsgFilters } from '../hooks/esgSubState';
import { generateFundSizeMarks } from '../hooks/fundSizeSubState';
import {
  countMoreFilters,
  noOfHoldingMarks,
  yieldMarks,
} from '../hooks/moreFiltersSubState';
import { ocfRangeMarks } from '../hooks/ocfSubState';
import { countRiskFilters } from '../hooks/riskSubState';
import useExploreFilters, { Filters } from '../hooks/useExploreFilters';
import { areFiltersEqual } from '../utils/filterComparisonHelpers';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import {
  mapToOptions,
  mapToOptionsWithIndex,
} from '../utils/mapDefaultOptionsToListItems';
import BondsFilterPanels from './BondsFilterPanels';
import EquitiesFilterPanels from './EquitiesFilterPanels';
import EsgFilterPanels from './EsgFilterMobilePanels';
import IaFilter from './IndividualFilters/IaSectors/IaFilter';
import MoreFiltersActivePassive from './IndividualFilters/MoreFilters/MoreFiltersActivePassive';
import MoreFiltersAssetClass from './IndividualFilters/MoreFilters/MoreFiltersAssetClass';
import MoreFiltersCurrencyHedged from './IndividualFilters/MoreFilters/MoreFiltersCurrencyHedged';
import MoreFiltersLegalStructure from './IndividualFilters/MoreFilters/MoreFiltersLegalStructure';
import MoreFiltersManagerGender from './IndividualFilters/MoreFilters/MoreFiltersManagerGender';
import MoreFiltersNumberOfHoldings from './IndividualFilters/MoreFilters/MoreFiltersNumberOfHoldings';
import MoreFiltersOldestShareclass from './IndividualFilters/MoreFilters/MoreFiltersOldestShareclass';
import MoreFiltersSearchWithCheckboxes from './IndividualFilters/MoreFilters/MoreFiltersSearchWithCheckboxes';
import MoreFiltersYield from './IndividualFilters/MoreFilters/MoreFiltersYield';
import CategoriesFilter from './IndividualFilters/MorningstarCategories/CategoriesFilter';
import FundSizeSlider from './IndividualFilters/RangeSliderDropdown/FundSizeSlider';
import OCFSlider from './IndividualFilters/RangeSliderDropdown/OCFSlider';
import RiskFilterPanels from './RiskFilterPanels';
import style from './style.module.less';

interface MobileExploreFiltersProps {
  isOpen: boolean;
  onClose: () => void;
  iaSectors: SearchDropdownItems[];
}

export type SearchDropdownItems<T = string> = {
  label: string;
  value?: T;

  // @deprecated - check within the component if the value intersects with the option
  checked?: boolean;
};

const MobileExploreFilters: React.FC<MobileExploreFiltersProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    tentativeFilters,
    syncFilters,
    clearAllTentativeFilters,
    getMutableFilters,
    updateTentativeFilters,
    filters,
  } = useExploreFilters();

  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const { data: currencyData } = useGetCurrencyRates();
  const fundSizeMarks = useMemo(() => {
    const symbol = currencyData?.currencyCode
      ? getSymbolFromCurrency(currencyData.currencyCode)
      : '£';
    return generateFundSizeMarks(symbol);
  }, [currencyData]);

  // Values from the server as options
  const { data } = useExploreDefaultFiltersQuery();

  const providerNames = useMemo(() => {
    return mapToOptionsWithIndex(data.providerNames);
  }, [data.providerNames]);

  const domiciles = useMemo(() => {
    return mapToOptions(data.domiciles);
  }, [data.domiciles]);

  const currencies = useMemo(() => {
    return mapToOptions(data.currencies);
  }, [data.currencies]);

  const platforms = useMemo(() => {
    return (
      data.platforms
        ?.map((platform: { value: string }) => {
          const platformFilter = PLATFORMS_FILTERS.find(
            item => item.key === platform.value,
          );
          return {
            label: platformFilter ? platformFilter.label : '',
            value: platformFilter ? platformFilter.key : '',
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [data.platforms]);

  const handleOnApply = () => {
    syncFilters();
    onClose();
  };

  const handleOnClear = () => {
    clearAllTentativeFilters();
  };

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(areFiltersEqual(filters, tentativeFilters));
  }, [tentativeFilters, filters]);

  const countAllFilters = (filters: Partial<Filters>) => {
    const baseCount =
      countRiskFilters(filters.riskFilters) +
      countBondsFilters(filters.bondsFilters) +
      countEquitiesFilters(filters.equitiesFilters) +
      countEsgFilters(filters.esgFilters) +
      countMoreFilters(filters.moreFilters);

    const additionalFilters = [
      filters.morningStarCategories?.selectedMorningStarCategoriesByIndex,
      filters.iaSectors?.selectedIaSectors,
      filters.fundSizeFilter?.fundSizeRange,
      filters.ocfFilter?.ocfRange,
    ];

    const additionalCount = additionalFilters.filter(
      filter => filter && filter.length > 0,
    ).length;

    return baseCount + additionalCount;
  };

  useEffect(() => {
    setClearDisabled(applyDisabled && countAllFilters(filters) === 0);
  });

  const allowedPeriodValues = [
    EDateFilterValues['1YR'],
    EDateFilterValues['3YR'],
    EDateFilterValues['5YR'],
  ];
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();

  const currentPeriod = useMemo(() => {
    if (allowedPeriodValues.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValues.some(apv => apv === p.value),
  ).map(period => ({
    key: period.value,
    label: period.label,
    onClick: () => {
      const filters = getMutableFilters();
      filters.riskFilters = filters.riskFilters ?? {};
      if (period.value === EDateFilterValues['1YR']) {
        filters.riskFilters.timePeriod = MsTimePeriod.ONE_YR;
      } else if (period.value === EDateFilterValues['3YR']) {
        filters.riskFilters.timePeriod = MsTimePeriod.THREE_YRS;
      } else if (period.value === EDateFilterValues['5YR']) {
        filters.riskFilters.timePeriod = MsTimePeriod.FIVE_YRS;
      }
      updateTentativeFilters(filters);
      setPeriod(parseDateValue(period.value), period.value);
    },
    selected: period.value === currentPeriod,
  }));

  return (
    <FullPageModal
      isOpen={isOpen}
      onClose={onClose}
      handleOnClear={handleOnClear}
      handleOnApply={handleOnApply}
      isDisableClear={clearDisabled}
      title="Filters"
      isDisableApply={applyDisabled}
    >
      <div className={cx(style['mobile-explore-filters'])}>
        <Accordion>
          {[
            {
              title: 'M* Category',
              dataTestId: 'mobile-dropdown-morningstar-category',
              extra: (
                <>
                  <span>
                    {(
                      tentativeFilters.morningStarCategories
                        ?.selectedMorningStarCategoriesByIndex ?? []
                    ).length > 0
                      ? `(${tentativeFilters.morningStarCategories?.selectedMorningStarCategoriesByIndex?.length})`
                      : ''}
                  </span>
                </>
              ),
              content: <CategoriesFilter />,
            },
            {
              title: 'IA Sector',
              dataTestId: 'mobile-dropdown-ia-sector',
              extra: (
                <>
                  <span>
                    {(tentativeFilters.iaSectors?.selectedIaSectors ?? [])
                      .length > 0
                      ? `(${tentativeFilters.iaSectors?.selectedIaSectors?.length})`
                      : ''}
                  </span>
                </>
              ),
              content: <IaFilter />,
            },
            {
              title: 'Fund Size',
              dataTestId: 'mobile-dropdown-fund-size',
              extra: (
                <>
                  <span>
                    {getChangedMarkValues(
                      tentativeFilters.fundSizeFilter?.fundSizeRange,
                      fundSizeMarks,
                    )}
                  </span>
                </>
              ),
              content: (
                <div
                  className={style['range-slider']}
                  data-test-id={'fund-size'}
                >
                  <FundSizeSlider />
                </div>
              ),
            },
            {
              title: 'OCF',
              dataTestId: 'mobile-dropdown-ocf',
              extra: getChangedMarkValues(
                tentativeFilters.ocfFilter?.ocfRange,
                ocfRangeMarks,
              ),
              content: (
                <div className={style['range-slider']} data-test-id={'ocf'}>
                  <OCFSlider />
                </div>
              ),
            },
            {
              title: (
                <div className="flex flex-col">
                  <p>Risk</p>
                  <span className="text-xs">Vs. category index</span>
                </div>
              ),
              extra: (
                <span>
                  {Number(countRiskFilters(tentativeFilters.riskFilters)) > 0
                    ? `(${countRiskFilters(tentativeFilters.riskFilters)})`
                    : ''}
                </span>
              ),
              dataTestId: 'mobile-dropdown-risk',
              content: (
                <>
                  <div>
                    <Dropdown
                      trigger={['click']}
                      placement="bottom"
                      className="dropdown"
                      menu={{
                        items: isMobile ? [] : allowedPeriodsForDropdown,
                      }}
                    >
                      <div
                        onClick={() =>
                          isMobile && setIsNestedDrawerVisible(true)
                        }
                        className="hover:fill-[#40a9ff] text-neutral fill-[#545576]"
                      >
                        <Button className="w-full text-neutral text-left flex items-center justify-between border border-light rounded hover:border-primary">
                          <p className="truncate text-sm mb-0 flex-grow">
                            {
                              allowedPeriodsForDropdown.find(
                                p => p.key === currentPeriod,
                              )?.label
                            }
                          </p>
                          <NextArrow className="w-3 rotate-90" />
                        </Button>
                      </div>
                    </Dropdown>
                    {isMobile && (
                      <NestedDrawer
                        menuItems={allowedPeriodsForDropdown}
                        visible={isNestedDrawerVisible}
                        onClose={() => setIsNestedDrawerVisible(false)}
                        title="Select"
                      />
                    )}
                  </div>
                  <div data-test-id={'risk'}>
                    <RiskFilterPanels />
                  </div>
                </>
              ),
            },
            {
              title: 'Equities',
              dataTestId: 'mobile-dropdown-equities',
              extra: (
                <span>
                  {Number(
                    countEquitiesFilters(tentativeFilters.equitiesFilters),
                  ) > 0
                    ? `(${countEquitiesFilters(
                        tentativeFilters.equitiesFilters,
                      )})`
                    : ''}
                </span>
              ),
              content: (
                <div data-test-id={'equities'}>
                  <EquitiesFilterPanels />
                </div>
              ),
            },
            {
              title: 'Bonds',
              dataTestId: 'mobile-dropdown-bonds',
              extra: (
                <span>
                  {Number(countBondsFilters(tentativeFilters.bondsFilters)) > 0
                    ? `(${countBondsFilters(tentativeFilters.bondsFilters)})`
                    : ''}
                </span>
              ),
              content: (
                <div data-test-id={'bonds'}>
                  <BondsFilterPanels />
                </div>
              ),
            },
            {
              title: 'ESG',
              dataTestId: 'mobile-dropdown-esg',
              extra: (
                <span>
                  {' '}
                  {Number(countEsgFilters(tentativeFilters.esgFilters)) > 0
                    ? `(${countEsgFilters(tentativeFilters.esgFilters)})`
                    : ''}
                </span>
              ),
              content: (
                <div data-test-id={'esg'}>
                  <EsgFilterPanels />
                </div>
              ),
            },
            {
              title: 'Oldest Shareclass',
              dataTestId: 'mobile-dropdown-oldest-shareclass',
              extra: (
                <>
                  <span>
                    {tentativeFilters.moreFilters?.filterByOldestShareClassOnly
                      ? '(1)'
                      : ''}
                  </span>
                </>
              ),
              content: <MoreFiltersOldestShareclass />,
            },
            {
              title: 'Number of Holdings',
              dataTestId: 'mobile-dropdown-number-of-holdings',
              extra: (
                <>
                  <span>
                    {getChangedMarkValues(
                      tentativeFilters.moreFilters?.noOfHoldingsRange,
                      noOfHoldingMarks,
                    )}
                  </span>
                </>
              ),
              content: <MoreFiltersNumberOfHoldings />,
            },
            {
              title: 'Active/Passive',
              dataTestId: 'mobile-dropdown-active-passive',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(
                      tentativeFilters.moreFilters?.filterByActiveOrPassive,
                    )}
                  </span>
                </>
              ),
              content: <MoreFiltersActivePassive />,
            },
            {
              title: 'Manager Gender',
              dataTestId: 'mobile-dropdown-manager-gender',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(tentativeFilters.moreFilters?.genders)}
                  </span>
                </>
              ),
              content: <MoreFiltersManagerGender />,
            },
            {
              title: 'Yield',
              dataTestId: 'mobile-dropdown-yield',
              extra: (
                <>
                  <span>
                    {getChangedMarkValues(
                      tentativeFilters.moreFilters?.yieldRange,
                      yieldMarks,
                    )}
                  </span>
                </>
              ),
              content: <MoreFiltersYield />,
            },
            {
              title: 'Management Group',
              dataTestId: 'mobile-dropdown-management-group',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(
                      tentativeFilters.moreFilters?.selectedManagementGroup,
                    )}
                  </span>
                </>
              ),
              content: (
                <MoreFiltersSearchWithCheckboxes
                  filterKey="selectedManagementGroup"
                  options={providerNames}
                />
              ),
            },
            {
              title: 'Asset Class',
              dataTestId: 'mobile-dropdown-asset-class',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(
                      tentativeFilters.moreFilters?.selectedBroadAssetClasses,
                    )}
                  </span>
                </>
              ),
              content: <MoreFiltersAssetClass />,
            },
            {
              title: 'Legal Structure',
              dataTestId: 'mobile-dropdown-legal-structure',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(
                      tentativeFilters.moreFilters?.legalStructure,
                    )}
                  </span>
                </>
              ),
              content: <MoreFiltersLegalStructure />,
            },
            {
              title: 'Domicile',
              dataTestId: 'mobile-dropdown-domicile',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(
                      tentativeFilters.moreFilters?.selectedDomiciles,
                    )}
                  </span>
                </>
              ),
              content: (
                <MoreFiltersSearchWithCheckboxes
                  filterKey="selectedDomiciles"
                  options={domiciles}
                />
              ),
            },
            {
              title: 'Currency',
              dataTestId: 'mobile-dropdown-currency',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(
                      tentativeFilters.moreFilters?.selectedCurrencies,
                    )}
                  </span>
                </>
              ),
              content: (
                <MoreFiltersSearchWithCheckboxes
                  filterKey="selectedCurrencies"
                  options={currencies}
                />
              ),
            },
            {
              title: 'Currency Hedged',
              dataTestId: 'mobile-dropdown-currency-hedged',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(
                      tentativeFilters.moreFilters?.currencyHedgedType,
                    )}
                  </span>
                </>
              ),
              content: <MoreFiltersCurrencyHedged />,
            },
            {
              title: 'Platform Availability',
              dataTestId: 'mobile-dropdown-platform-availability',
              extra: (
                <>
                  <span>
                    {lengthOrEmpty(
                      tentativeFilters.moreFilters?.selectedPlatforms,
                    )}
                  </span>
                </>
              ),
              content: (
                <>
                  <MoreFiltersSearchWithCheckboxes
                    filterKey="selectedPlatforms"
                    options={platforms}
                  />
                  <div className={style['platform-note-wrapper']}>
                    <p className={style['platform-note-title']}>Note:</p>
                    <p className={style['platform-note-text']}>
                      These checkboxes are AND conditions <br />
                      (i.e. a fund must be present on Wealthtime AND Parmenion)
                    </p>
                  </div>
                </>
              ),
            },
          ].map((element, index) => (
            <Panel
              header={<div className="font-normal">{element.title}</div>}
              dataTestId={element.dataTestId}
              key={index}
              extra={element.extra}
            >
              {element.content}
            </Panel>
          ))}
        </Accordion>
      </div>
    </FullPageModal>
  );
};

export default MobileExploreFilters;
