import { NoteBody } from '@aminsights/contract';
import React, { useState } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { Button } from '@/components';

import Analyst from './Analyst';
import MainEmptyState from './MainEmptyState';
import CardNotes from './MainEmptyState/component/CardNotes';
import DropdownMenuWithModal from './MainEmptyState/component/DropdownMenuWithModal';
import Meetings from './Meetings';
import Ratings from './Ratings';

const Research: React.FC = () => {
  const [noteData, setNoteData] = useState<NoteBody[]>([]);

  const handleNoteSubmit = (note: NoteBody) => {
    setNoteData(prevNotes => [...prevNotes, note]);
  };

  return (
    <div className="grid lg:grid-cols-[1fr_360px] gap-4 md:px-4 pt-2">
      <div className="w-full bg-white flex flex-col gap-20 p-4 min-h-[592px]">
        <div className="flex items-center justify-between">
          <h3 className="text-base font-semibold text-neutral-900">Note</h3>
          {noteData.length > 0 && (
            <div className="flex gap-4 items-center ">
              <Button
                className="text-xs leading-5 rounded text-neutral-300 !border !border-neutral-300 hover:!border-primary hover:!text-primary"
                type="default"
              >
                Publish notes
              </Button>
              <DropdownMenuWithModal onSubmit={handleNoteSubmit} />
              <div className="hover:bg-grey-light-50 cursor-pointer py-1.5 rounded">
                <MeatballMenu
                  width={16}
                  height={16}
                  className="text-neutral-700 mx-2"
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-center">
          {noteData.length <= 0 ? (
            <div className="mt-20 flex flex-col items-center">
              <MainEmptyState
                onClick={() => {}}
                title="You don’t have any notes yet"
                subTitle="Start adding your notes by clicking on the button below."
                buttonLabel="Add a note"
              />
              <DropdownMenuWithModal onSubmit={handleNoteSubmit} />
            </div>
          ) : (
            <div className="mt-6 w-full flex flex-col gap-6">
              {noteData.map((note, index) => (
                <CardNotes
                  key={index}
                  noteCategory={note.category}
                  note={note.description}
                  status={note.status}
                  date="2021-09-29"
                  author="John Doe"
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        <Analyst />
        <Meetings />
        <Ratings />
      </div>
    </div>
  );
};

export default Research;
