import { Modal } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as IconCheck } from '@/assets/svg/icons/icon-check.svg';
import { ReactComponent as IconCopy } from '@/assets/svg/icons/icon-copy.svg';
import { ReactComponent as IconWarning } from '@/assets/svg/icons/icon-warning.svg';
import { Button, DataTable } from '@/components';
import Progress from '@/components/Progress';
import { IDataTableColumns } from '@/components/Table/DataTable';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import {
  FailedStatus,
  UnavailableISINsItems,
  UploadModalInfoItems,
} from '@/pages/app/Watchlist/Buckets/components/ImportFunds/types';

import style from './style.module.less';

type ModalProps = {
  isSuccess: boolean;
  failedStatusModalInfo: UploadModalInfoItems;
  fileNames?: string[];
  isVisible: boolean;
  loading?: boolean;
  className?: string;
  toggleModal: () => void;
};

const UploadModal: React.FCWithChild<ModalProps> = ({
  isVisible,
  className,
  isSuccess,
  fileNames,
  failedStatusModalInfo,
  toggleModal,
}) => {
  const [hasCopiedMessage, setHasCopiedMessage] = useState(false);
  const { title, description, failedStatus, unavailableISINsList } =
    failedStatusModalInfo;

  const columns: IDataTableColumns[] = [
    {
      title: '#',
      render: (item: UnavailableISINsItems) => (
        <p className="font-medium text-darkest">{item.rowNumber}</p>
      ),
      isColumnFixed: true,
      renderType: 'text',
      width: 50,
    },
    {
      title: 'ISIN',
      render: (item: UnavailableISINsItems) => (
        <span className={style['filename-error']}>{item.isin}</span>
      ),
      width: 150,
      renderType: 'text',
      align: 'left',
    },
  ];

  const onClickCopy = () => {
    if (unavailableISINsList && unavailableISINsList.length) {
      navigator.clipboard.writeText(
        unavailableISINsList.map(fund => fund.isin).join(', '),
      );
    }
    if (navigator.clipboard) {
      setHasCopiedMessage(true);
    }
  };

  const renderUploadComplete = () => (
    <>
      <div className="text-sm font-normal pt-3 text-neutral-700">
        You may now add a name and assign a benchmark to your uploaded
        portfolio.
      </div>
      <div className="pt-3">
        {fileNames &&
          fileNames.map(value => (
            <div className={style['uploaded-files']} key={value}>
              <div className="flex justify-between">
                <p className={style['filename']}>{value}</p>
                <p className={style['filename']}>100%</p>
              </div>
              <Progress percent={100} showInfo={false} />
            </div>
          ))}
      </div>
    </>
  );

  const renderUploadFailed = () => {
    return (
      <>
        {failedStatus !== FailedStatus.ISINS_NOT_FOUND && (
          <>
            <div className="text-sm font-normal pt-3 text-neutral-700">
              {description}
            </div>
            {fileNames &&
              fileNames.map(value => (
                <div className={style['uploaded-files']} key={value}>
                  <div className="flex justify-between">
                    <p className={style['filename-error']}>{value}</p>
                  </div>
                  <Progress percent={100} showInfo={false} status="exception" />
                </div>
              ))}
          </>
        )}
        {failedStatus === FailedStatus.ISINS_NOT_FOUND && (
          <>
            <div className="text-sm font-normal pt-3 text-neutral-700">
              {description}
            </div>
            <div className={style['copy-wrapper']} onClick={onClickCopy}>
              {hasCopiedMessage ? (
                <>
                  <IconCheck className={style['icon']} />
                  <p className="pl-1">Copied!</p>
                </>
              ) : (
                <>
                  <IconCopy className={style['icon']} />
                  <p className="pl-1">Copy</p>
                </>
              )}
            </div>

            <div className="pt-5">
              <DataTable
                columns={columns}
                uniqueKey="isin"
                data={unavailableISINsList}
                className={style['isins-table']}
              />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <Modal
      width={600}
      centered={true}
      open={isVisible}
      onCancel={() => {
        if (hasCopiedMessage) setHasCopiedMessage(false);
        toggleModal();
      }}
      title={
        isSuccess ? (
          <div className="flex items-center gap-x-4">
            <IconCheck width={20} height={20} style={{ fill: '#449B47' }} />
            <div className="text-xl text-darkest font-bold">
              Upload Complete
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-x-4">
            <IconWarning width={20} height={20} fill="#CF3B31" />
            <Loader
              width="100px"
              component={
                <div className="text-xl font-bold text-darkest">{title}</div>
              }
            />
          </div>
        )
      }
      className={cx(
        'max-sm:full-page-modal action-modal [&_.ant-modal-header]:mb-0 [&_.ant-modal-header]:pb-4',
        className,
      )}
      footer={
        failedStatus !== FailedStatus.ISINS_NOT_FOUND
          ? [
              <Button
                onClick={toggleModal}
                type="link"
                size="large"
                className={cx(style['cancel-btn'], 'm-0')}
              >
                {isSuccess ? 'Continue' : 'Cancel'}
              </Button>,
            ]
          : []
      }
      closable={failedStatus === FailedStatus.ISINS_NOT_FOUND || isSuccess}
    >
      {isSuccess ? renderUploadComplete() : renderUploadFailed()}
    </Modal>
  );
};

export default UploadModal;
