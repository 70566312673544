import { Tooltip } from 'antd';
import cx from 'classnames';

import { ReactComponent as IconClose } from '@/assets/svg/icons/icon-close-light.svg';
import { ReactComponent as IconFolder } from '@/assets/svg/icons/icon-folder.svg';
import { ReactComponent as IconFolderColored } from '@/assets/svg/icons/icon-folder-colored.svg';
import { ReactComponent as IconFolderOpen } from '@/assets/svg/icons/icon-folder-open.svg';

import style from './style.module.less';

type BucketListProps = {
  id: string;
  bucketName: string;
  fundCount: number;
  tooltipTitle?: string;
  isBucketActive?: boolean;
  isBucketSelected?: boolean;
  isBucketDisabled?: boolean;
  onBucketSelected: (id: string) => void;
  onBucketRemoved?: (id: string) => void;
};

const BucketList: React.FCWithChild<BucketListProps> = ({
  id,
  bucketName,
  fundCount,
  tooltipTitle,
  isBucketActive,
  isBucketSelected,
  isBucketDisabled,
  onBucketSelected,
  onBucketRemoved,
}) => {
  const handleToggle = () => {
    if (!isBucketActive && !isBucketDisabled) {
      onBucketSelected(id);
    }
  };

  const handleRemove = () => {
    if (onBucketRemoved) {
      onBucketRemoved(id);
    }
  };

  const renderFolderIcon = () => {
    if (isBucketActive) {
      return <IconFolderOpen className={style['icon-folder']} />;
    }
    if (isBucketSelected) {
      return <IconFolderColored />;
    }

    return <IconFolder className={style['icon-folder']} />;
  };

  const isBucketRemovable = !!onBucketRemoved;

  return (
    <Tooltip
      title={tooltipTitle}
      placement="bottomLeft"
      color="#3E414B"
      overlayStyle={{ pointerEvents: 'none' }}
    >
      <div
        onClick={handleToggle}
        role="button"
        tabIndex={0}
        className={cx(
          style['bucket-list'],
          'text-neutral-700 font-normal text-sm',
          {
            [style['bucket-list-selected']]: isBucketSelected,
            [style['bucket-list-active']]: isBucketActive,
            [style['bucket-list-disabled']]: isBucketDisabled,
          },
        )}
      >
        <div className="flex items-center">
          <span className={cx(style['bucket-list-folder-icon'])}>
            {renderFolderIcon()}
          </span>
          <p className="pl-2">{bucketName}</p>
          <p className="pl-1">({fundCount})</p>
        </div>
        {isBucketRemovable && (
          <Tooltip
            title="Remove as focus fund"
            placement="bottom"
            color="#313341"
          >
            <IconClose className={style['icon-close']} onClick={handleRemove} />
          </Tooltip>
        )}
      </div>
    </Tooltip>
  );
};

export default BucketList;
