import { Button, ButtonProps, Modal, Radio, Space } from 'antd';
import cx from 'classnames';
import { FC, useState } from 'react';

import { ReactComponent as MobileFiltersIcon } from '@/assets/svg/icons/icon-filter-modal.svg';
import { useChartSettingsState } from '@/hooks/useChartSettingsState';

import Accordion, { Panel } from '../Accordion';

const PANEL_KEYS = [
  'investment-trusts-returns',
  'exclude-recent-launches',
] as const;

interface ChartSettingsProps {
  buttonProps?: ButtonProps;
  hidePanelKeys?:
    | (typeof PANEL_KEYS)[number][]
    | (typeof PANEL_KEYS)[number]
    | false;
}

const ChartSettings: FC<ChartSettingsProps> = ({
  buttonProps,
  hidePanelKeys,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    getMutableChartSettingsFilter,
    applyChartSettingsFilter,
    updateTentativeChartSettingsFilter,
    chartSettingsFilter,
  } = useChartSettingsState();

  const { investmentTrustsReturns, excludeRecentLaunches } =
    getMutableChartSettingsFilter();

  let countOfFilters = 0;
  if (chartSettingsFilter.investmentTrustsReturns === 'NAV') countOfFilters++;
  if (chartSettingsFilter.excludeRecentLaunches === 'Exclude') countOfFilters++;

  let shouldHidePanelKeys: (typeof PANEL_KEYS)[number][] | undefined;
  // False was for convenience in parent functions
  if (hidePanelKeys === false) {
    shouldHidePanelKeys = undefined;
  } else {
    shouldHidePanelKeys =
      typeof hidePanelKeys === 'string' ? [hidePanelKeys] : hidePanelKeys;
  }

  return (
    <>
      <div className="relative">
        <Button
          icon={<MobileFiltersIcon />}
          className={`rounded-[4px] w-[40px] h-[40px] ${buttonProps?.className}`}
          onClick={() => setIsModalOpen(true)}
        />
        {countOfFilters > 0 && (
          <div className="absolute top-0 right-0 h-5 w-5 bg-info rounded-full flex items-center justify-center translate-x-1/2 -translate-y-1/2">
            <span className="text-xxs text-white px-1">{countOfFilters}</span>
          </div>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            size="large"
            key="cancel"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>,
          <Button
            size="large"
            key="apply"
            type="primary"
            onClick={() => {
              applyChartSettingsFilter();
              setIsModalOpen(false);
            }}
          >
            Apply
          </Button>,
        ]}
        className={cx(
          'max-sm:full-page-modal [&_.ant-modal-body]:min-h-[352px] [&_.ant-modal-content]:md:min-w-[600px]',
          'information-modal [&_.ant-modal-footer]:md:!justify-between [&_.ant-modal-body]:md:min-h-120',
        )}
      >
        <div>
          <h3 className="text-xl font-bold mt-8">Filters</h3>
          <Accordion
            defaultActiveKey={[
              'exclude-recent-launches',
              'investment-trusts-returns',
            ]}
            accordion={false}
          >
            <Panel
              className={cx(
                shouldHidePanelKeys?.includes('investment-trusts-returns') &&
                  'hidden',
              )}
              key="investment-trusts-returns"
              header="Investment Trusts Returns"
              extra={investmentTrustsReturns === 'NAV' && `(1)`}
            >
              <Radio.Group
                onChange={e => {
                  const f = getMutableChartSettingsFilter();
                  f.investmentTrustsReturns = e.target.value;
                  updateTentativeChartSettingsFilter(f);
                }}
                value={getMutableChartSettingsFilter().investmentTrustsReturns}
              >
                <Space direction="vertical">
                  <Radio value="NAV">Nav</Radio>
                  <Radio value="Share Price">Share Price</Radio>
                </Space>
              </Radio.Group>
            </Panel>
            <Panel
              key="exclude-recent-launches"
              header="Exclude Recent Launches"
              extra={excludeRecentLaunches === 'Exclude' && `(1)`}
            >
              <Radio.Group
                onChange={e => {
                  const f = getMutableChartSettingsFilter();
                  f.excludeRecentLaunches = e.target.value;
                  updateTentativeChartSettingsFilter(f);
                }}
                value={getMutableChartSettingsFilter().excludeRecentLaunches}
              >
                <Space direction="vertical">
                  <Radio value="Include">Include</Radio>
                  <Radio value="Exclude">Exclude</Radio>
                </Space>
              </Radio.Group>
              <p className="text-xs text-neutral mt-4">
                This includes funds that launched most recently than the time
                frame selected.
              </p>
            </Panel>
          </Accordion>
        </div>
      </Modal>
    </>
  );
};

export default ChartSettings;
