import { Button, Select } from 'antd';
import cx from 'classnames';
import { FC } from 'react';

import { MAX_ANALISTS } from '.';

interface AnalystFormProps {
  selectedAnalysts: string[];
  options: { value: string; label: string }[];
  toggleForm: () => void;
  onChangeAnalysts: (value: string[]) => void;
  onSubmitForm: () => void;
}

const AnalystForm: FC<AnalystFormProps> = ({
  selectedAnalysts,
  options,
  toggleForm,
  onChangeAnalysts,
  onSubmitForm,
}) => {
  return (
    <div className="flex flex-col gap-4 mt-2">
      <div className="flex-1 flex flex-col">
        <Select
          placement="bottomLeft"
          mode="multiple"
          data-test-id="researchAnalystSelect"
          placeholder="Ex. John, Jennifer,..."
          suffixIcon={<></>}
          className={cx(
            'w-full min-h-10 custom-antd-selection',
            '[&_.ant-select-selection-placeholder]:text-neutral-100 [&_.ant-select-selection-placeholder]:px-1',
            '[&_.ant-select-selector]:p-2 [&_.ant-select-selector]:rounded [&_.ant-select-selector]:ring-0',
            '[&_.ant-select-selector]:border-neutral-300 [&_.ant-select-selector]:hover:border-primary',
            '[&_.ant-select-selector]:focus-within:border-2 [&_.ant-select-selector]:focus-within:border-primary',
          )}
          maxCount={MAX_ANALISTS}
          value={selectedAnalysts}
          options={options.map(option => ({ ...option, title: '' }))}
          onChange={onChangeAnalysts}
          optionFilterProp="label"
        />
        <p className="text-xs text-neutral mt-1">
          Search and select up to {MAX_ANALISTS} names from the list.
        </p>
      </div>
      <div className="flex gap-4 justify-end">
        <Button size="large" type="link" onClick={toggleForm}>
          Cancel
        </Button>
        <Button
          className="disabled:border-none disabled:bg-neutral-300 disabled:text-white"
          size="large"
          type="primary"
          onClick={onSubmitForm}
          disabled={!selectedAnalysts.length}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default AnalystForm;
