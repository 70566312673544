import './style.less';

import { Bucket, Fund } from '@aminsights/contract';
import {
  getFundShareClassDetailsFromArray,
  LIMIT_FUNDS_PER_BUCKET,
} from '@aminsights/shared';
import { Dropdown, Tooltip } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as IconArrowDownStroke } from '@/assets/svg/icons/icon-arrow-down-stroke.svg';
import { ReactComponent as IconEyeAdd } from '@/assets/svg/icons/icon-eye-add.svg';
import { ReactComponent as IconEyeVisible } from '@/assets/svg/icons/icon-eye-hide.svg';
import { ReactComponent as IconFolder } from '@/assets/svg/icons/icon-folder.svg';
import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as IconScales } from '@/assets/svg/icons/icon-scales.svg';
import Button from '@/components/Button';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import {
  useAddFundsToBucket,
  useAssignAsFocusFund,
  useRemoveFundsFromBucket,
} from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import WatchlistModal from '@/partials/Modal/WatchlistModal';
import BucketList from '@/partials/Modal/WatchlistModal/BucketList';
import WrapperMultiSelectBucketModal from '@/partials/Modal/WrapperMultiSelectBucketModal';
import NestedDrawer from '@/partials/NestedDrawer';
import SectionBackButton from '@/partials/Sections/SectionBackButton';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import BasePageWithMetadata from '../../BasePageWithMetadata';
import CompareFundSelectModal from './CompareTool/CompareFundSelectModal';
import FundOptionsDropdown from './FundOptionsDropdown';

interface IFundDetailsHeaderProps {
  isLoading?: boolean;
  fund: Fund | undefined;
}

export const FundTitleHeader: React.FCWithChild<IFundDetailsHeaderProps> = ({
  isLoading,
  fund,
}) => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  // const isSmallMobile = screenWidthMode[ScreenWidthEnum.MaxSm];
  const currentWatchlist = useCurrentWatchlist();
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  // Breaking down into smaller variables
  const buckets = currentWatchlist.data?.buckets;
  const addFundsToBucket = useAddFundsToBucket();
  const removeFundsFromBucket = useRemoveFundsFromBucket();
  const assignAsFocusFund = useAssignAsFocusFund();

  const [isWatching, setIsWatching] = useState<boolean>(false);
  const [bucketsWithCurrentFund, setBucketsWithCurrentFund] = useState<
    Bucket[]
  >([]);
  const [selectedBucket, setSelectedBucket] = useState<string[]>([]);
  const [bucketsRemoved, setBucketsRemoved] = useState<string[]>([]);
  const [isAddToWatchlistModalOpen, setIsAddToWatchlistModalOpen] =
    useState(false);
  const [isRemoveFromWatchlistModalOpen, setIsRemoveFromWatchlistModalOpen] =
    useState(false);
  const [isRemoveBucketsModalOpen, setIsRemoveBucketsModalOpen] =
    useState(false);
  const [isAddToBucketsModalOpen, setIsAddToBucketsModalOpen] = useState(false);
  const [isFocusFundModalOpen, setIsFocusFundModalOpen] = useState(false);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const featureSwitch = useFeatureSwitchContext();
  const { isAppLimitedAccessEnabled } = featureSwitch.state;

  const subMenuItems = [
    {
      key: '1',
      label: 'Manage focus fund',
      onClick: () => {
        setIsFocusFundModalOpen(true),
          filterBucketsWithCurrentFund(),
          setSelectedBucket([]);
      },
    },
    {
      key: '2',
      label: 'Add to Buckets',
      onClick: () => {
        setIsAddToBucketsModalOpen(true),
          filterBucketsWithCurrentFund(),
          setSelectedBucket([]);
      },
    },
    {
      key: '3',
      label: 'Remove from buckets',
      onClick: () => {
        setIsRemoveBucketsModalOpen(true),
          filterBucketsWithCurrentFund(),
          setSelectedBucket([]);
      },
    },
  ];

  const shareClassCode = fund?.shareClassDetails.code;

  const handleAddWatchlistModalOpen = () => {
    setIsAddToWatchlistModalOpen(prev => !prev);
  };

  const handleRemoveWatchlistModalOpen = () => {
    setIsRemoveFromWatchlistModalOpen(prev => !prev);
  };

  const handleRemoveBucketsModal = () => {
    setIsRemoveBucketsModalOpen(prev => !prev);
    setSelectedBucket([]);
    setBucketsWithCurrentFund([]);
  };

  const handleAddBucketsModal = () => {
    setIsAddToBucketsModalOpen(prev => !prev);
    setBucketsWithCurrentFund([]);
  };

  const handleToggleFocusFundModal = () => {
    setIsFocusFundModalOpen(prev => !prev);
    setSelectedBucket([]);
    setBucketsRemoved([]);
    setBucketsWithCurrentFund([]);
  };

  const filterBucketsWithCurrentFund = (): void => {
    if (buckets?.length) {
      const selectedFund = fund?.id;

      const selectedFundBucketRelated = buckets?.filter(item =>
        item.funds.some(fund => fund.isin === selectedFund),
      );
      setBucketsWithCurrentFund(selectedFundBucketRelated);
    }
  };

  useEffect(() => {
    if (buckets?.length) {
      const isinsFromWatchlist = buckets.some(b =>
        b.funds?.some(f => f.isin === fund?.shareClassDetails.isin),
      );
      setIsWatching(isinsFromWatchlist);
    }
  }, [buckets, fund?.shareClassDetails.isin]);

  const setToWatch = () => {
    setIsWatching(prev => !prev);
  };

  const onDeleteClick = async () => {
    const bucketIdsWithFund = buckets
      ? buckets
          .filter(bucket =>
            bucket.funds.some(bf => bf.isin === fund?.shareClassDetails.isin),
          )
          .map(bucket => bucket.id)
      : [];
    await Promise.all(
      bucketIdsWithFund.map(bucketId =>
        removeFundsFromBucket.mutateAsync({
          isins: fund ? [fund.shareClassDetails.isin] : [],
          bucketId,
        }),
      ),
    );
  };

  const handleBucketSelected = (bucketId: string): void => {
    if (selectedBucket.includes(bucketId)) {
      // If bucketId exists in the array, remove it
      setSelectedBucket(selectedBucket.filter(id => id !== bucketId));
    } else {
      // If bucketId doesn't exist, add it to the array
      setSelectedBucket([...selectedBucket, bucketId]);
    }
  };

  const handleBucketRemoved = (bucketId: string): void => {
    // If bucketId doesn't exist in the array, add it
    if (!bucketsRemoved.includes(bucketId)) {
      setBucketsRemoved([...bucketsRemoved, bucketId]);
    }
  };

  const removeFundFromSelectedBuckets = async (): Promise<void> => {
    if (fund) {
      await Promise.all(
        selectedBucket.map(bucketId =>
          removeFundsFromBucket.mutateAsync({
            isins: [fund.shareClassDetails.isin],
            bucketId,
          }),
        ),
      );
      setSelectedBucket([]);
    }
  };

  const addFundFromSelectedBuckets = async (): Promise<void> => {
    if (fund) {
      await Promise.all(
        selectedBucket.map(bucketId =>
          addFundsToBucket.mutateAsync({
            isins: [fund.shareClassDetails.isin],
            bucketId: bucketId,
          }),
        ),
      );
      setSelectedBucket([]);
    }
  };

  const assignFocusFundToBuckets = async (): Promise<void> => {
    if (fund) {
      const unassignFund = bucketsRemoved
        .filter(bucketId => !selectedBucket.includes(bucketId))
        .map(bucketId => ({
          bucketId: bucketId,
          isin: fund.shareClassDetails.isin,
          isFeatured: false,
        }));

      const assignFund = selectedBucket
        .filter(bucketId => !bucketsRemoved.includes(bucketId))
        .map(bucketId => ({
          bucketId: bucketId,
          isin: fund.shareClassDetails.isin,
          isFeatured: true,
        }));

      await assignAsFocusFund.mutateAsync([...unassignFund, ...assignFund]);
      setSelectedBucket([]);
      setBucketsRemoved([]);
    }
  };

  const bucketsWithCurrentFundIds = bucketsWithCurrentFund.map(({ id }) => id);

  const isAssignFocusFund = (bucket: Bucket) => {
    const isFocusFund = bucket.funds.find(
      f => f.isin === fund?.shareClassDetails.isin,
    )?.isFeatured;

    return isFocusFund;
  };

  const isAlreadyInBucket = (bucket: Bucket) => {
    return bucketsWithCurrentFundIds.includes(bucket.id);
  };

  return (
    <BasePageWithMetadata
      title={`${
        shareClassCode ? shareClassCode : fund?.fundName || 'Fund details'
      }`}
    >
      <div className="flex flex-row justify-between md:flex-col fund-title">
        <SectionBackButton previousLabel={!isMobile ? 'Back' : ''} />
        <div className="flex justify-end w-full px-4 pt-2 pb-4 md:justify-between">
          {!isMobile && (
            <Loader
              width="300px"
              loading={isLoading}
              component={
                <h1
                  className="text-xl font-bold text-darkest"
                  data-test-id="fundDetailsHeaderFundName"
                >
                  {shareClassCode || fund?.fundName}
                </h1>
              }
            />
          )}
          {!isAppLimitedAccessEnabled && (
            <Loader
              width="200px"
              loading={isLoading}
              component={
                !isLoading && (
                  <div className="flex btn-with-dropdown">
                    <Tooltip
                      title={isMobile ? '' : 'Add to watchlist'}
                      placement="bottom"
                      color="#3E414B"
                    >
                      <Button
                        type="primary"
                        className={cx('btn-watching', {
                          'watching-bg': isWatching,
                          'watching-bg-not': !isWatching,
                        })}
                        data-test-id="fundDetailsHeaderAddToWatchlistButton"
                        onClick={() => {
                          setIsAddToWatchlistModalOpen(!isWatching);
                          setIsRemoveFromWatchlistModalOpen(isWatching);
                          filterBucketsWithCurrentFund();
                        }}
                      >
                        {isWatching || isLoading ? (
                          <IconEyeVisible className="text-xl icon" />
                        ) : (
                          <IconEyeAdd className="text-xl icon" />
                        )}
                      </Button>
                    </Tooltip>
                    <div
                      className={cx({
                        'separator-watching-bg': isWatching,
                        'separator-watching-bg-not': !isWatching,
                      })}
                    />
                    {!isAppLimitedAccessEnabled && (
                      <>
                        <Dropdown
                          className={cx({
                            'watching-bg': isWatching,
                            'watching-bg-not': !isWatching,
                          })}
                          menu={{ items: isMobile ? [] : subMenuItems }}
                          placement="bottomRight"
                          trigger={['click']}
                        >
                          <div
                            className="flex items-center justify-center text-white rounded-tr rounded-br cursor-pointer w-9 h-9"
                            data-test-id="fundDetailsHeaderBucketDropdown"
                            onClick={() =>
                              isMobile && setIsNestedDrawerVisible(true)
                            }
                          >
                            <IconArrowDownStroke className="text-xl icon btn-sub-action-icon" />
                          </div>
                        </Dropdown>
                        {isMobile && (
                          <NestedDrawer
                            menuItems={subMenuItems}
                            visible={isNestedDrawerVisible}
                            onClose={() => setIsNestedDrawerVisible(false)}
                            title="Select"
                          />
                        )}
                      </>
                    )}
                    {!isAppLimitedAccessEnabled && (
                      <>
                        <Tooltip
                          title={isMobile ? '' : 'Compare funds'}
                          placement="bottom"
                          color="#3E414B"
                        >
                          <Button
                            data-test-id="fundDetailsHeaderCompareButton"
                            type="primary"
                            className={cx('btn-watching', 'compare-btn', {
                              'watching-bg': isWatching,
                              'watching-bg-not': !isWatching,
                            })}
                            onClick={() => {
                              setIsCompareModalOpen(true);
                            }}
                          >
                            <IconScales className="text-xl icon" />
                          </Button>
                        </Tooltip>
                        <FundOptionsDropdown />
                      </>
                    )}
                  </div>
                )
              }
            />
          )}
        </div>
      </div>
      {isMobile && (
        <Loader
          width="300px"
          loading={isLoading}
          component={
            <h1 className="justify-between w-full px-5 pb-4 overflow-hidden text-xl font-bold break-words text-darkest">
              {shareClassCode || fund?.fundName}
            </h1>
          }
        />
      )}
      <WatchlistModal
        isins={fund ? [fund?.shareClassDetails.isin] : []}
        fundName={shareClassCode || fund?.fundName}
        isVisible={isAddToWatchlistModalOpen}
        toggleModal={handleAddWatchlistModalOpen}
        addToWatchlist={setToWatch}
      />
      <ConfirmationModalDanger
        modalInfo={{
          title: `Remove '${shareClassCode || fund?.fundName}' from watchlist?`,
          description:
            'Removing a fund from your Watchlist will also remove it from the associated bucket(s).',
          primaryActionLabel: 'Remove',
          succesMessage: 'Successfully removed from watchlist',
          errorMessage: 'Fund not removed from watchlist',
        }}
        isVisible={isRemoveFromWatchlistModalOpen}
        toggleModal={handleRemoveWatchlistModalOpen}
        onConfirm={onDeleteClick}
        width={560}
        className="max-sm:full-page-modal max-sm:[&_.ant-modal-header]:shadow-sm max-sm:[&_.ant-modal-footer]:drop-shadow-md"
      >
        <div className="mt-6 px-4 py-3 w-auto rounded-lg overflow-y-auto border border-neutral-50 h-[280px]">
          {bucketsWithCurrentFund?.map((bucket, idx) => (
            <div key={idx} className="flex items-center h-6">
              <IconFolder className="mr-2" />
              <div className="text-sm text-darkest">{bucket.name}</div>
            </div>
          ))}
        </div>
      </ConfirmationModalDanger>

      <WrapperMultiSelectBucketModal
        modalInfo={{
          title: 'Manage focus fund',
          description: (
            <p className="text-sm font-normal leading-5 text-neutral">
              <b className="pb-1 font-medium text-regular">
                Select bucket(s) to assign as focus fund
              </b>
              <br />
              This fund has been assigned to these buckets. Please select
              bucket(s) to assign as focus fund.
            </p>
          ),
          btnName: 'Save',
          succesMessage: 'Successfully saved changes',
          errorMessage: 'Error assign/unassign as focus fund',
        }}
        isVisible={isFocusFundModalOpen}
        toggleModal={handleToggleFocusFundModal}
        onClickAction={assignFocusFundToBuckets}
        width={720}
        isUserSelectBucket={
          selectedBucket.length <= 0 && bucketsRemoved.length <= 0
        }
      >
        <div
          className={cx(
            'mt-6 w-auto rounded-lg border overflow-hidden py-1',
            'border-[#adadb3]',
          )}
        >
          <div className="overflow-y-auto h-[280px] px-4 py-2 rounded-lg">
            {bucketsWithCurrentFund.map(item => {
              item.funds;
              return (
                <BucketList
                  key={`$bucket-list-${item.id}`}
                  id={item.id}
                  bucketName={item.name || ''}
                  fundCount={item.funds ? item.funds?.length : 0}
                  isBucketActive={
                    isAssignFocusFund(item) && !bucketsRemoved.includes(item.id)
                  }
                  isBucketSelected={selectedBucket.includes(item.id)}
                  onBucketSelected={handleBucketSelected}
                  onBucketRemoved={handleBucketRemoved}
                />
              );
            })}
          </div>
        </div>
      </WrapperMultiSelectBucketModal>

      <WrapperMultiSelectBucketModal
        modalInfo={{
          title: `Remove from buckets`,
          description: (
            <>
              Select the buckets to remove{' '}
              <b className="font-semibold">‘{fund?.fundName}‘</b>
            </>
          ),
          btnName: 'Remove',
          succesMessage: 'Successfully removed from bucket(s)',
          errorMessage: 'Fund not removed from bucket(s)',
        }}
        isVisible={isRemoveBucketsModalOpen}
        toggleModal={handleRemoveBucketsModal}
        onClickAction={removeFundFromSelectedBuckets}
        width={720}
        isUserSelectBucket={selectedBucket.length <= 0}
        className={cx(
          '[&_.primary-button:not(:disabled)]:!bg-destructive-500',
          '[&_.ant-modal-footer]:sm:!justify-end',
        )}
      >
        <div
          className={cx(
            'mt-6 w-auto rounded-lg border overflow-hidden py-1',
            'border-[#adadb3]',
          )}
        >
          <div className="overflow-y-auto h-[280px] px-4 py-2 rounded-lg">
            {bucketsWithCurrentFund.map(item => {
              return (
                <BucketList
                  key={`$bucket-list-${item.id}`}
                  id={item.id}
                  bucketName={item.name || ''}
                  fundCount={item.funds ? item.funds?.length : 0}
                  isBucketSelected={selectedBucket.includes(item.id)}
                  onBucketSelected={() => {
                    handleBucketSelected(item.id);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="flex  mt-2" style={{ color: '#6F707A' }}>
          <InfoIcon className="mt-1 mr-1 shrink-0 min-w-0" />
          <p className="text-xs not-italic font-normal leading-5">
            Removing a fund from all your buckets will remove it from your
            watchlist.
          </p>
        </div>
      </WrapperMultiSelectBucketModal>

      <WrapperMultiSelectBucketModal
        modalInfo={{
          title: `Add to buckets`,
          description: (
            <>
              Select the buckets to add{' '}
              <b className="font-semibold">‘{fund?.fundName}‘</b>
            </>
          ),
          btnName: 'Add',
          succesMessage: 'Successfully added to bucket(s)',
          errorMessage: 'Fund not added to bucket(s)',
        }}
        isVisible={isAddToBucketsModalOpen}
        toggleModal={handleAddBucketsModal}
        onClickAction={addFundFromSelectedBuckets}
        width={720}
        isUserSelectBucket={selectedBucket.length <= 0}
        secondaryClickOption={true}
      >
        <div
          className={cx(
            'mt-6 w-auto rounded-lg border overflow-hidden py-1',
            'border-[#adadb3]',
          )}
        >
          <div className="overflow-y-auto h-[280px] px-4 py-2 rounded-lg">
            {buckets?.map(item => {
              const fundCount = item.funds ? item.funds?.length : 0;
              const isBucketFull =
                fundCount >= LIMIT_FUNDS_PER_BUCKET ||
                (fundCount + 1 >= LIMIT_FUNDS_PER_BUCKET &&
                  isAlreadyInBucket(item));

              const tooltipTitle = isAlreadyInBucket(item)
                ? 'Fund is already in this bucket'
                : isBucketFull
                ? 'Max. 11 funds per bucket'
                : '';

              return (
                <BucketList
                  key={`$bucket-list-${item.id}`}
                  id={item.id}
                  bucketName={item.name || ''}
                  fundCount={item.funds ? item.funds?.length : 0}
                  tooltipTitle={tooltipTitle}
                  isBucketSelected={selectedBucket.includes(item.id)}
                  isBucketDisabled={isAlreadyInBucket(item) || isBucketFull}
                  onBucketSelected={() => {
                    handleBucketSelected(item.id);
                  }}
                />
              );
            })}
          </div>
        </div>
      </WrapperMultiSelectBucketModal>
      <CompareFundSelectModal
        isOpen={isCompareModalOpen}
        setIsOpen={setIsCompareModalOpen}
        initialFunds={
          fund
            ? [
                getFundShareClassDetailsFromArray(
                  [fund],
                  fund?.shareClassDetails.isin,
                ),
              ]
            : []
        }
      />
    </BasePageWithMetadata>
  );
};
