import { ChartsApi, UnboxedChartDataResponse } from '@aminsights/contract';
import {
  RipsReturnType,
  unboxChartDataItemsToChartDataSeries,
} from '@aminsights/shared';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  CHARTS,
  CHARTS_RELATIVE_WITH_PORTFOLIO,
} from '../watchlist-hooks/query-keys';

export interface ChartParams {
  period: string[];
  isins: string[];
  benchmarkId?: string;
  ripsReturnType?: number;
  investmentTrustReturn?: RipsReturnType;
  featuredIsins?: string[];
  retainSortOrder?: boolean;
  portfolios?: string[];
  excludeRecentLaunches?: boolean;
}

const useGetRelativeChartDataWithPortfolio = (
  params: ChartParams,
  options?: Omit<
    UseQueryOptions<
      UnboxedChartDataResponse | null,
      unknown,
      UnboxedChartDataResponse
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const {
    period,
    isins,
    benchmarkId,
    investmentTrustReturn,
    featuredIsins,
    retainSortOrder,
    portfolios,
    excludeRecentLaunches,
  } = params;
  const chartApi = new ChartsApi(openApiConfig());

  const enabled =
    (isins.length !== 0 || portfolios?.length !== 0) && period.length === 2;

  return useQuery<
    UnboxedChartDataResponse | null,
    unknown,
    UnboxedChartDataResponse
  >(
    [CHARTS, CHARTS_RELATIVE_WITH_PORTFOLIO, params],
    async () => {
      if (!enabled) return null;

      const response = await chartApi.getRelativeChartDataWithPortfolio(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        portfolios,
        excludeRecentLaunches,
      );
      const unboxedData = unboxChartDataItemsToChartDataSeries(
        response.data?.data,
      );
      return {
        ...response?.data,
        data: unboxedData,
      };
    },
    {
      ...options,
    },
  );
};

export default useGetRelativeChartDataWithPortfolio;
