import {
  BenchmarkGroup as BenchmarkOptionGroupEnum,
  BenchmarkSource,
  CompositeBenchmark,
  PortfolioBenchmarkSettingTypeEnum,
  PortfolioPayload,
} from '@aminsights/contract';
import clsx from 'classnames';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Controller,
  FieldErrorsImpl,
  SubmitErrorHandler,
  useForm,
} from 'react-hook-form';

import { ReactComponent as PlusIcon } from '@/assets/svg/icons/icon-plus.svg';
import { Input } from '@/components';
import Button from '@/components/Button';
import { SimpleAlert } from '@/components/SimpleAlert';
import { MAX_ENTITY_NAME } from '@/constants';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useProvidePortfolio } from '@/pages/app/Portfolio/useProvidePortfolio';
import BenchmarksDropdown, {
  BenchmarksDropdownRef,
} from '@/partials/BenchmarksDropdown';
import CompositeBenchmarkFormModal from '@/partials/CompositeBenchmarks/CompositeBenchmarkFormModal';

import style from './style.module.less';
import {
  PortfolioFormErrorToIgnore,
  UpsertPortfolioFields,
  UpsertPortfolioFormFields,
} from './type';
import { transformPortfolioPayload } from './utils';

type UpsertPortolioFormProps = {
  portfolio: PortfolioPayload;
  submitting: boolean;
  setIsFormValid?: (isValid: boolean) => void;
  onSubmit?: (values: UpsertPortfolioFields) => void;
  onChange?: (values: UpsertPortfolioFields) => void;
  error: string | null;
  disableActions?: boolean;
  onButtonDisabled?: (isDisabled?: boolean) => void;
};

export interface UpsertPortfolioFormRef {
  onSave?: () => void;
  reset?: () => void;
}

export const emptyPortfolioForm = {
  _id: '',
  name: '',
  benchmarkSetting: {
    id: '',
    type: PortfolioBenchmarkSettingTypeEnum.Regular,
  },
  funds: [],
};

const evaluateErrorsToBlockSubmission = (
  errors: Partial<FieldErrorsImpl<PortfolioPayload>>,
  errorsToIgnore: PortfolioFormErrorToIgnore[],
) => {
  const result = [];
  for (const err in errors) {
    const errorToIgnore = errorsToIgnore.find(eI => eI.name === err);
    if (errorToIgnore) {
      const extractedError = errors[errorToIgnore.name];
      if (extractedError?.type === errorToIgnore.type) {
        continue;
      }
    }
    result.push(err);
  }
  return result;
};

export const UpsertPortfolioForm = forwardRef<
  UpsertPortfolioFormRef,
  UpsertPortolioFormProps
>(
  (
    {
      setIsFormValid,
      onSubmit,
      onChange,
      submitting,
      portfolio,
      error,
      disableActions,
      onButtonDisabled,
    },
    ref,
  ) => {
    const [isCompositeBenchmarkModalOpen, setIsCompositeBenchmarkModalOpen] =
      useState(false);
    const {
      data,
      isLoading,
      refetch: refetchBenchmarkOptions,
    } = useBenchmarkOptions([
      BenchmarkSource.Ami,
      BenchmarkSource.Msci,
      BenchmarkSource.Morningstar,
    ]);

    const isPortfolioNew = portfolio._id === '';
    const { state } = useProvidePortfolio();
    const authContext = useContext(AxiosAuthContext);
    const currentUserId = authContext.state.decodedToken?.sub;
    const { benchmarkSetting } = portfolio;
    const benchmarksDropdownRef = useRef<BenchmarksDropdownRef>(null);

    const {
      control,
      getValues,
      handleSubmit,
      clearErrors,
      reset,
      setValue,
      formState: { isDirty, errors },
    } = useForm<UpsertPortfolioFormFields>({
      mode: 'all',
      defaultValues: {
        name: portfolio.name,
        benchmarkSetting: {
          id: benchmarkSetting.compositeBenchmarkId || benchmarkSetting.secId,
          type: benchmarkSetting.type,
        },
      },
    });

    const onFormSubmit = (values: UpsertPortfolioFormFields) => {
      const payload = transformPortfolioPayload(values);
      onSubmit && onSubmit(payload);
      if (isPortfolioNew) {
        // Reset to default empty portfolio values
        reset();
      } else {
        // Reset form to the current value to remove isDirty from a form
        reset(values);
      }
    };

    const errorsToIgnore: PortfolioFormErrorToIgnore[] = [
      { name: 'name', type: 'maxLength' },
    ];

    const errorsToBlockSubmission = evaluateErrorsToBlockSubmission(
      errors,
      errorsToIgnore,
    );

    useEffect(() => {
      setIsFormValid && setIsFormValid(errorsToBlockSubmission.length === 0);
    }, [errorsToBlockSubmission]);

    //WORKAROUND: we want to show some errors, but still be able to sumbit: e.g. name has 15 symbols limit
    const onInvalidSubmit: SubmitErrorHandler<UpsertPortfolioFields> = () => {
      if (errorsToBlockSubmission.length === 0) {
        const values = getValues();
        onFormSubmit(values);
      }
    };

    const invokeOnBenchmarkChange = async (
      value: string,
      benchmarkType: PortfolioBenchmarkSettingTypeEnum,
    ) => {
      if (benchmarkType === PortfolioBenchmarkSettingTypeEnum.Composite) {
        await refetchBenchmarkOptions();
      }
      setValue('benchmarkSetting.id', value);
      setValue('benchmarkSetting.type', benchmarkType);
    };

    const saveDisabled =
      !isDirty || Object.keys(errorsToBlockSubmission).length !== 0;

    useEffect(() => {
      onButtonDisabled?.(saveDisabled);
    }, [saveDisabled]);

    useImperativeHandle(ref, () => ({
      onSave: () => {
        if (errorsToBlockSubmission.length === 0) {
          clearErrors();
        }
        handleSubmit(onFormSubmit, onInvalidSubmit)();
      },
      reset: () => {
        benchmarksDropdownRef?.current?.close?.();
      },
    }));

    return (
      <>
        <form
          onChange={() => {
            if (onChange) {
              const values = getValues();
              const payload = transformPortfolioPayload(values);
              onChange(payload);
            }
          }}
          onSubmit={(submitEvent: React.FormEvent<HTMLFormElement>) => {
            if (errorsToBlockSubmission.length === 0) {
              clearErrors();
            }
            handleSubmit(onFormSubmit, onInvalidSubmit)(submitEvent);
          }}
          className={style['upsert-portfolio__form']}
        >
          <Controller
            control={control}
            name={'name'}
            rules={{
              required: 'Portfolio name is required',
              maxLength: {
                value: MAX_ENTITY_NAME,
                message:
                  'We recommend keeping your Portfolio name to less than 10 characters.',
              },
              validate: {
                duplicatedName: value => {
                  const hasDuplicate = state.portfolios.some(p => {
                    if (p._id === portfolio._id && portfolio._id !== '') {
                      return false;
                    }
                    return p.name === value.trim();
                  });
                  if (hasDuplicate) {
                    return 'Portfolio name already exists';
                  }
                  return;
                },
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error: fieldError },
            }) => (
              <div className="flex flex-col gap-1 mb-4">
                <label
                  htmlFor="name"
                  className="text-xs font-medium text-neutral-200"
                >
                  Name
                </label>
                <div className="flex flex-col gap-y-1">
                  <Input
                    autoFocus
                    id="name"
                    dataTestId="upsertPortfolioNameInput"
                    name="name"
                    type="text"
                    value={value}
                    placeholder="Enter Portfolio Name"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={fieldError?.message}
                    disabled={disableActions}
                    className="placeholder:!text-neutral-450"
                  />
                  {!fieldError?.message && (
                    <p className="text-xs text-neutral font-normal">
                      We recommend keeping your Portfolio name to less than 10
                      characters.
                    </p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name="benchmarkSetting.id"
            render={({ field: { value, onChange: onBenchmarkChange } }) => (
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="name"
                  className="text-xs font-medium text-neutral-200"
                >
                  Benchmark
                </label>
                <BenchmarksDropdown
                  dropdownKey={`benchmark-dropdown`}
                  className={style['upsert-portfolio__fields-dropdown']}
                  placeholder="Select benchmark"
                  value={value}
                  benchmarkOptions={
                    data?.filter(bo => {
                      if (bo.group !== BenchmarkOptionGroupEnum.Custom)
                        return true;

                      return (
                        bo.createdBy === currentUserId ||
                        bo.shared ||
                        bo.id === value
                      );
                    }) ?? []
                  }
                  onSelect={(seletedValue, groupValue) => {
                    const group =
                      groupValue === BenchmarkOptionGroupEnum.Custom
                        ? PortfolioBenchmarkSettingTypeEnum.Composite
                        : PortfolioBenchmarkSettingTypeEnum.Regular;
                    onBenchmarkChange(seletedValue);
                    invokeOnBenchmarkChange(seletedValue, group);
                  }}
                  onClear={() => {
                    setValue('benchmarkSetting.id', '', { shouldDirty: true });
                    setValue(
                      'benchmarkSetting.type',
                      PortfolioBenchmarkSettingTypeEnum.Regular,
                      { shouldDirty: true },
                    );
                  }}
                  dataTestId={`dropDownBenchmarks`}
                  disabled={disableActions}
                  isOptionsLoading={isLoading}
                  ref={benchmarksDropdownRef}
                />
              </div>
            )}
          />
          {error && (
            <div className="mb-6">
              <SimpleAlert type="warning" message={error} showIcon closable />
            </div>
          )}

          {!disableActions && (
            <div className={style['add-benchmark-index']}>
              <button
                className={style['add-benchmark-index__button']}
                type="button"
                onClick={e => {
                  e.preventDefault();
                  setIsCompositeBenchmarkModalOpen(true);
                }}
              >
                <PlusIcon
                  className={clsx(
                    'icon',
                    style['add-benchmark-index__button-icon'],
                  )}
                />
                <p className={style['add-benchmark-index__button-text']}>
                  Add custom benchmark
                </p>
              </button>
            </div>
          )}

          {!disableActions && onSubmit && (
            <div
              className={clsx(
                'flex flex-row landscape:justify-end md:justify-end mt-3 portfolio-save-btn',
              )}
            >
              <Button
                disabled={saveDisabled}
                type="primary"
                size="large"
                htmlType="submit"
                data-test-id="upsertPortfolioSaveButton"
                loading={submitting}
              >
                {isPortfolioNew ? 'Create' : 'Save Changes'}
              </Button>
            </div>
          )}
        </form>
        <CompositeBenchmarkFormModal
          isVisible={isCompositeBenchmarkModalOpen}
          toggleModal={() => {
            setIsCompositeBenchmarkModalOpen(prev => !prev);
          }}
          benchmarks={
            data?.filter(bo => bo.group === BenchmarkOptionGroupEnum.Custom) ??
            []
          }
          onSubmit={(benchmark: CompositeBenchmark) => {
            invokeOnBenchmarkChange(
              benchmark._id,
              PortfolioBenchmarkSettingTypeEnum.Composite,
            );
          }}
        />
      </>
    );
  },
);
