import { APP_ROUTE_WATCHLIST, isInvestmentTrust } from '@aminsights/shared';
import { Tabs } from 'antd';
import React, { useMemo, useState } from 'react';

import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import AbsoluteChart from '@/partials/Charts/absolute/AbsoluteChart';
import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import queryClient from '@/queryClient';

import { EmptyTab } from '../../Watchlist/Watchlist/components/EmptyTab';

const FundPerformance: React.FCWithChild = () => {
  const { data, isLoading } = useCurrentWatchlist();
  const buckets = data?.buckets;
  const [selectedBucketIndex, setSelectedBucketIndex] = useState<number>(0);
  const selectedBucket = buckets?.[selectedBucketIndex];

  const isins = useMemo(
    () => selectedBucket?.funds?.map(fund => fund.isin) ?? [],
    [selectedBucket],
  );
  const featuredIsins = useMemo(
    () =>
      selectedBucket?.funds?.filter(f => f.isFeatured)?.map(f => f?.isin) || [],
    [selectedBucket],
  );

  const hasInvestmentTrust = useMemo(() => {
    if (data) {
      /* This code is checking if the current watchlist has any investment trusts in a specific bucket. */
      const bucket = data?.buckets?.find(
        bucket => bucket.id === selectedBucket?.id,
      );
      const result = bucket?.funds?.some(fund =>
        isInvestmentTrust(fund?.legalStructure),
      );
      return result ?? false;
    }
  }, [data]);

  const isEmpty = isins.length === 0;

  return (
    <div
      className="pb-6 mb-6 md:pt-0 bg-white lg:rounded-lg"
      data-test-id="chartFundPerformance"
    >
      <div className="flex flex-col justify-between md:flex-row md:items-center py-4 px-4">
        <h5 className="text-sm font-bold text-darkest">Fund Performance</h5>
      </div>
      <Tabs
        defaultActiveKey="0"
        onChange={bucketId => {
          const bucketIndex = buckets?.findIndex(b => b.id === bucketId);
          // Async call to get new bucket data, it'll fire a re-render if new data is available
          queryClient.invalidateQueries([WATCHLIST]);

          if (bucketIndex) {
            /**
             * Note that this works well because we count on the fact that
             * useCurrentWatchlist is set to `keepPreviousData: true`.
             */
            setSelectedBucketIndex(bucketIndex);
          } else {
            setSelectedBucketIndex(0);
          }
        }}
        items={
          buckets?.map(bucket => ({
            label: (
              <span data-test-id={`chartTabFundPerformance-${bucket.id}`}>
                {bucket.name || ''}
              </span>
            ),
            key: bucket.id,
            className: 'tab-tabs',
          })) ?? []
        }
      />
      <div className="px-4">
        {!isEmpty || isLoading ? (
          <>
            <div className="py-2">
              <AbsoluteChartTitle
                hasInvestmentTrust={hasInvestmentTrust}
                showDropdown={true}
              />
            </div>
            <AbsoluteChart
              isins={isins}
              featuredIsins={featuredIsins}
              benchmarkId={selectedBucket?.indexSecId}
              sectorId={selectedBucket?.sector}
              hasInvestmentTrust={hasInvestmentTrust}
              showDropdown={true}
            />
          </>
        ) : (
          <EmptyTab
            title="No added funds/trusts"
            subTitle="Add funds to your watchlist"
            redirectLink={`/${APP_ROUTE_WATCHLIST}/manage-buckets`}
          />
        )}
      </div>
    </div>
  );
};

export default FundPerformance;
