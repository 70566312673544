import { Drawer } from 'antd';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as CheckActive } from '@/assets/svg/icons/icon-check-active.svg';
import { ReactComponent as Arrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import VirtualizedList from '@/pages/app/Explore/components/VirtualizedList';

interface MenuItem {
  key: string;
  label?: string | JSX.Element;
  selected?: boolean;
  onClick?: () => void;
  children?: MenuItem[];
  childComponent?: {
    title: string;
    content: React.ReactNode; // Replaces the menu entirely
    footer?: React.ReactNode;
  };
}

interface MenuLevel {
  items: MenuItem[];
  subComponent?: React.ReactNode; // sits above the menu
  footer?: React.ReactNode;
}

interface NestedDrawerProps {
  menuItems: MenuItem[];
  visible: boolean;
  onClose: () => void;
  title?: string;
  subComponent?: React.ReactNode;
  footer?: React.ReactNode;
}

const NestedDrawer: React.FC<NestedDrawerProps> = ({
  menuItems,
  visible,
  onClose,
  title,
  subComponent,
  footer,
}) => {
  const [menuStack, setMenuStack] = useState<MenuLevel[]>([
    { items: menuItems, subComponent, footer },
  ]);
  const [currentChildComponentPath, setCurrentChildComponentPath] = useState<
    string[] | null
  >(null);
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [direction, setDirection] = useState(0); // 1 for forward, -1 for backward
  const [drawerTitle, setDrawerTitle] = useState(title);

  const currentLevel = {
    ...menuStack[menuStack.length - 1],
    key: menuStack.length - 1,
  };

  const handleItemClick = (item: MenuItem, itemPath: string[]) => {
    if (item.children) {
      setDirection(1); // Forward navigation
      setMenuStack([
        ...menuStack,
        {
          items: item.children,
          subComponent: subComponent,
          footer,
        },
      ]);
      setDrawerTitle(item.label as string);
      setCurrentChildComponentPath(null);
      setCurrentPath(itemPath);
    } else if (item.childComponent) {
      setDirection(1); // Forward navigation
      setCurrentChildComponentPath(itemPath);
      setDrawerTitle(item.label as string);
    } else if (item.onClick) {
      item.onClick();
      onClose();
    }
  };

  const handleBack = () => {
    if (currentChildComponentPath) {
      setDirection(-1); // Backward navigation
      setCurrentChildComponentPath(null);
      setDrawerTitle(title);
    } else if (menuStack.length > 1) {
      setDirection(-1); // Backward navigation
      const previousMenuItem = get(menuItems, currentPath.slice(0, -2));
      setMenuStack(menuStack.slice(0, -1));
      setCurrentPath(currentPath.slice(0, -2));
      setDrawerTitle((previousMenuItem?.label as string) || title);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!visible) {
      setMenuStack([{ items: menuItems, subComponent, footer }]);
      setCurrentChildComponentPath(null);
      setCurrentPath([]);
      setDirection(0);
    } else {
      setMenuStack([{ items: menuItems, subComponent, footer }]);
      setDrawerTitle(title);
    }
  }, [visible, menuItems, subComponent, footer]);

  const variants = {
    enter: (custom: number) => ({
      x: custom > 0 ? '100%' : '-100%',
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (custom: number) => ({
      x: custom < 0 ? '100%' : '-100%',
      opacity: 0,
    }),
  };

  const currentChildComponent = currentChildComponentPath
    ? get(menuItems, currentChildComponentPath.concat('childComponent'))
    : null;

  const renderItemByIndex = (index: number) => {
    const item = currentLevel.items[index];
    const itemPath = currentPath.concat(index.toString());
    return (
      <MenuItemComponent
        key={item.key}
        item={item}
        onItemClick={handleItemClick}
        itemPath={itemPath}
      />
    );
  };

  const [drawerHeight, setDrawerHeight] = useState('auto');
  const subComponentRef = useRef<HTMLDivElement>(null);
  const [subComponentHeight, setSubComponentHeight] = useState(0);

  useEffect(() => {
    if (currentChildComponent) {
      setDrawerHeight('750px');
    } else {
      const baseHeight = 70;
      const itemHeight = 38;
      const calculatedHeight =
        baseHeight + currentLevel.items.length * itemHeight;

      const maxHeight = window.innerHeight * 0.95;
      setDrawerHeight(`${Math.min(calculatedHeight, maxHeight)}px`);
    }
  }, [currentChildComponent, currentLevel.key]);

  useEffect(() => {
    const height = subComponentRef.current?.clientHeight || 0;
    if (height !== 0) {
      setSubComponentHeight(height);
    }
  }, [subComponent]);

  return (
    <Drawer
      title={
        <div className="text-regular font-bold text-sm flex items-center gap-2">
          {(menuStack.length > 1 || currentChildComponent) && (
            <Arrow
              onClick={handleBack}
              className="cursor-pointer w-3 rotate-180 fill-[#3b3c53] text-neutral-700"
            />
          )}
          {drawerTitle}
        </div>
      }
      className="[&_.ant-drawer-close]:absolute [&_.ant-drawer-close]:right-0 [&_.ant-drawer-close]:mr-4"
      open={visible}
      onClose={onClose}
      placement="bottom"
      styles={{
        header: {
          padding: 16,
        },
        wrapper: {
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          overflow: 'hidden',
          height: drawerHeight,
        },
        body: {
          position: 'relative',
          overflow: 'scroll',
          padding: 0,
        },
      }}
      footer={currentChildComponent?.footer || footer}
    >
      <AnimatePresence custom={direction} initial={false}>
        <motion.div
          key={
            currentChildComponentPath
              ? currentChildComponentPath.join('-')
              : menuStack.length
          }
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.3 }}
          style={{
            position: 'absolute',
            width: '100%',
            height: `calc(100% - ${subComponentHeight}px)`,
          }}
        >
          {currentChildComponent ? (
            currentChildComponent.content
          ) : (
            <>
              {currentLevel.subComponent && (
                <div
                  className="pt-2 [&_.ant-checkbox-group]:!px-0"
                  ref={subComponentRef}
                >
                  {currentLevel.subComponent}
                </div>
              )}
              <div className="h-full w-full">
                <VirtualizedList
                  items={currentLevel.items.map(item => ({
                    label: item.label as string,
                    value: item.key,
                  }))}
                  renderItemByIndex={renderItemByIndex}
                />
              </div>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </Drawer>
  );
};

interface MenuItemComponentProps {
  item: MenuItem;
  onItemClick: (item: MenuItem, itemPath: string[]) => void;
  itemPath: string[];
}

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({
  item,
  onItemClick,
  itemPath,
}) => {
  return (
    <div
      onClick={() => onItemClick(item, itemPath)}
      className={cx(
        'text-neutral-700 font-normal py-2 px-6 flex items-center justify-between cursor-pointer hover:text-primary',
        item.selected && 'text-primary',
        '[&_.ant-checkbox-wrapper]:py-0',
      )}
    >
      <span>{item.label}</span>
      <span className="flex items-center gap-2 text-[10px]">
        <span>
          {item.selected && <CheckActive className="text-primary pt-0.5" />}
        </span>
        {(item.childComponent || item.children) && (
          <span>
            <Arrow className={cx('icon text-[10px]')} />
          </span>
        )}
      </span>
    </div>
  );
};

export default NestedDrawer;
