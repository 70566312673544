import { buildFundDetailsPath } from '@aminsights/shared';
import { Skeleton } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as TrendIcon } from '@/assets/svg/icons/icon-trend-arrow.svg';
import Tooltip from '@/components/Tooltip';

import { summaryCardMapping, SummaryCardProps } from './types';

const SummaryCard: React.FCWithChild<SummaryCardProps> = ({
  cardItem,
  loading,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  if (!cardItem && !loading) {
    return null;
  }

  return (
    <div className="snap-start mr-6 last:mr-0">
      <div className="p-4 bg-white rounded-lg shadow-card h-full w-[296px]">
        <div className="flex justify-between items-center mb-1.5 gap-x-2">
          <Link
            to={buildFundDetailsPath(cardItem?.fundIsin || '')}
            className="text-sm text-darkest font-bold truncate overflow-ellipsis flex-auto"
          >
            <Skeleton
              title={false}
              paragraph={{ rows: 1 }}
              loading={loading}
              active
            >
              {cardItem?.fundName}
            </Skeleton>
          </Link>
          <div className="flex justify-end text-sm text-darkest font-medium">
            <Tooltip
              title={
                cardItem &&
                (summaryCardMapping.get(cardItem.cardType)?.tooltipMessage ||
                  'Last 30 days')
              }
              placement="bottomRight"
              color="#3E414B"
              visible={tooltipVisible}
              onOpenChange={setTooltipVisible}
              arrow={{ pointAtCenter: true }}
              className="!w-6 !h-6"
            >
              <InfoIcon
                className={cx('icon text-neutral mx-1', {
                  'text-neutral-100': tooltipVisible,
                })}
              />
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="w-full text-xs text-normal text-neutral">
            <Skeleton
              title={false}
              paragraph={{ rows: 1 }}
              active
              loading={loading}
            >
              {cardItem && summaryCardMapping.get(cardItem.cardType)?.title}
            </Skeleton>
          </div>
          <div className="flex items-center mt-2">
            {cardItem && (
              <span
                className={cx(
                  'p-2 mr-3 rounded-full flex items-center justify-center text-center',
                  {
                    'text-success-trend bg-success bg-opacity-5':
                      cardItem.value >= 0,
                    'text-danger-trend bg-destructive-500 bg-opacity-5':
                      cardItem.value < 0,
                  },
                )}
              >
                <TrendIcon
                  className={cx(
                    'icon text-xl &_path:stroke-width-2.4 !fill-none',
                    cardItem.value >= 0
                      ? '[transform:rotate(180deg)_scaleX(-1)]'
                      : '',
                  )}
                />
              </span>
            )}
            <h1 className="text-2xl text-darkest font-bold">
              <Skeleton
                title={false}
                paragraph={{ rows: 1 }}
                active
                loading={loading}
              >
                {cardItem && `${cardItem.value.toFixed(1)}%`}
              </Skeleton>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
