import { ChartDataTag } from '@aminsights/contract';
import { AreaConfig } from '@ant-design/charts';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetRelativeChartData from '@/hooks/query-hooks/chart-hooks/useGetRelativeChartData';
import {
  getInvestmentTrustReturnType,
  useChartSettingsState,
} from '@/hooks/useChartSettingsState';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { parseRangeValues, useDatePickerContext } from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';
import {
  getLegendCardClasses,
  getLegendIdToRemove,
  getLegendSuperTag,
  getLegendTargetLink,
} from '@/utils';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { BaseChartProps } from '../components/BaseChart';
import { PerformanceCardDetails } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import { getIdOrderByLegendData } from '../utils/get-order';
import useChartColors from '../utils/useChartColors';
import style from './style.module.less';

interface RelativeChartProps extends BaseChartProps {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  scrollable?: boolean;
}

const RelativeChart: React.FCWithChild<RelativeChartProps> = ({
  showFundLinks = true,
  onRemoveFund,
  isins,
  benchmarkId,
  featuredIsins,
  scrollable,
}) => {
  const { value: datePickerValue } = useDatePickerContext();
  const [legendData, setLegendData] = useState<PerformanceCardDetails[]>([]);

  // Contemplating whether to move this out or not
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { chartSettingsFilter } = useChartSettingsState();
  const { excludeRecentLaunches, investmentTrustsReturns } =
    chartSettingsFilter;

  const { data, isLoading, isFetching } = useGetRelativeChartData({
    benchmarkId,
    isins,
    period,
    investmentTrustReturn: getInvestmentTrustReturnType(
      investmentTrustsReturns,
    ),
    featuredIsins,
    excludeRecentLaunches: excludeRecentLaunches === 'Exclude',
  });
  const relativeData = data?.data || [];
  const chartColors = useChartColors({
    isins,
    legend: data?.legendData || [],
  });
  const isChartLoaded = useMemo(() => {
    if (!isLoading && !!data) {
      if (chartColors) {
        return !!Object.keys(chartColors).length;
      }
      return true;
    }
    return false;
  }, [isLoading, data, chartColors]);

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        // Seperate into transform func
        data.legendData
          .filter(f => f.dataTag !== ChartDataTag.Benchmark)
          .map(f => ({
            ...f,
            tooltip: f.tooltip || '',
            value: f.value || '',
            id: f.id || '',
            label: f.label || '',
            dataTag: f.dataTag,
            color: chartColors[f.id || ''] || '',
            date: f.date || '',
          })),
      );
    }
  }, [data, chartColors]);

  const tooltipOrder = useMemo(
    () => getIdOrderByLegendData(data?.legendData),
    [data?.legendData],
  );

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: relativeData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [relativeData, chartColors],
  );
  // if isins are not defined - show loader no matter what
  // this will mean that we are waiting for isins to be defined to be provided from the parent control

  if (!relativeData.length && !isLoading && !isFetching) {
    return (
      <ChartEmptyState
        title={'No data available for this fund'}
        isDashboardPage={true}
      />
    );
  }

  return (
    <div>
      {isChartLoaded && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          enableHoverEffects={
            !!featuredIsins?.length &&
            legendData.some(d => d.dataTag === ChartDataTag.Featured)
          }
          dataTestId="relativeChart"
          onUpdateLegend={setLegendData}
          scrollable={scrollable}
          tooltipOrder={tooltipOrder}
        />
      )}
      {(isLoading || !isChartLoaded) && (
        <img className="w-full mt-5 mb-5" src={loaderImage} />
      )}
      <div
        data-test-id="relativeChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .sort(
              (a, b) => tooltipOrder.indexOf(a.id) - tooltipOrder.indexOf(b.id),
            )
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                id={getLegendIdToRemove(legendItem)}
                tooltip={legendItem.tooltip || legendItem.label}
                color={legendItem.color}
                value={legendItem.value}
                superTag={getLegendSuperTag(legendItem.dataTag)}
                targetLink={
                  showFundLinks ? getLegendTargetLink(legendItem) : undefined
                }
                cardClasses={getLegendCardClasses(
                  legendItem.dataTag,
                  legendItem.isActive,
                )}
                onRemove={onRemoveFund}
                date={legendItem.date}
              />
            ))}
        />
      </div>
      {/* Divider is here because it is only on charts page */}
      <div className={style.divider} />
    </div>
  );
};

export default RelativeChart;
