import { AuthUser, Comment } from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT, USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Popover } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import NestedDrawer from '@/partials/NestedDrawer';
import { validatePermissions } from '@/utils/auth';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import style from './style.module.less';

interface CommentaryListItemProps {
  comment: Comment;
  user?: AuthUser;
  onDeleteComment: (comment: Comment) => void;
  highlight?: boolean;
}

const CommentaryListItem: FC<CommentaryListItemProps> = ({
  comment,
  user,
  onDeleteComment,
  highlight,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRecentComment, setIsRecentComment] = useState(false);
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  useEffect(() => {
    const isRecent = dayjs(comment._createdAt).isAfter(
      new Date().getTime() - 1000 * 10,
    );

    let timeout: ReturnType<typeof setTimeout>;
    if (isRecent) {
      setIsRecentComment(true);
      timeout = setTimeout(() => {
        setIsRecentComment(false);
      }, 1000 * 10);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  const handleToggleDeleteModal = () => {
    setIsDeleteModalOpen(prev => !prev);
  };

  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.comments.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const hasAdminPermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.comments.admin],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const isCommentOwner = currentUser.sub === comment.createdBy;
  const showDeleteButton =
    (hasWritePermissions && isCommentOwner) || hasAdminPermissions;

  return (
    <>
      <ConfirmationModalDanger
        modalInfo={{
          title: 'Delete Commentary?',
          description: 'This action cannot be undone.',
          primaryActionLabel: 'Delete',
          succesMessage: `Commentary has been deleted`,
          errorMessage: `Unable to delete Commentary`,
        }}
        width={560}
        isVisible={isDeleteModalOpen}
        toggleModal={handleToggleDeleteModal}
        onConfirm={async () => {
          await onDeleteComment(comment);
        }}
      />
      <div
        className={cx(
          style['comment-wrapper'],
          'flex flex-col gap-5 p-4 rounded-lg group border border-[#eaebec]',
          { 'border-secondary': isRecentComment || highlight },
        )}
      >
        <div className="flex gap-4 md:items-center relative">
          <div className="flex-1 flex flex-col gap-y-2 justify-between md:flex-row md:items-center">
            <p className="text-sm font-semibold">
              {dayjs(comment._createdAt).format(DISPLAY_DATE_FORMAT)}
            </p>
            <p className={cx(style['comment-wrapper__created-by'], 'text-xs')}>
              Created by:{' '}
              <span className="font-semibold">{user?.name ?? 'User'}</span>
            </p>
          </div>
          {showDeleteButton && (
            <Popover
              placement="bottomRight"
              trigger="click"
              getPopupContainer={triggerNode =>
                triggerNode.parentElement as HTMLElement
              }
              content={
                !isMobile && (
                  <div className="w-32">
                    <Button
                      type="link"
                      className={cx(
                        style['comment-wrapper__action-button'],
                        'p-0',
                      )}
                      onClick={handleToggleDeleteModal}
                    >
                      Delete
                    </Button>
                  </div>
                )
              }
            >
              <MeatballMenu
                className={cx(
                  style['comment-wrapper__action-icon'],
                  'p-1 h-6 rounded-full cursor-pointer',
                )}
                onClick={() => isMobile && setIsNestedDrawerVisible(true)}
              />
            </Popover>
          )}
          {isMobile && (
            <NestedDrawer
              menuItems={[
                {
                  label: 'Delete',
                  key: 'delete',
                  onClick: handleToggleDeleteModal,
                },
              ]}
              visible={isNestedDrawerVisible}
              onClose={() => setIsNestedDrawerVisible(false)}
              title="Select"
            />
          )}
        </div>
        <p className="text-sm font-normal text-justify text-neutral-200 w-full whitespace-pre-wrap">
          {comment.description}
        </p>
      </div>
    </>
  );
};

export default CommentaryListItem;
