import {
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  MsTimePeriod,
} from '@aminsights/shared';
import { Button, Modal } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { Dropdown } from '@/components';
import useExploreFilters from '@/pages/app/Explore/hooks/useExploreFilters';
import { toCamelCase } from '@/utils/toCamelCase';

import { parseDateValue, useDatePickerContext } from '../DatePicker';

type ModalProps = {
  title?: string;
  description?: string;
  size?: number;
  isVisible: boolean;
  isDisableApply: boolean;
  isDisableClear: boolean;
  className?: string;
  onApply?: () => void;
  toggleModal: () => void;
  onClear: () => void;
  showDropdown?: boolean;
};

const ExploreFilterModal: React.FCWithChild<ModalProps> = ({
  title,
  description,
  size,
  children,
  isVisible,
  isDisableApply,
  isDisableClear,
  className,
  toggleModal,
  onClear,
  onApply,
  showDropdown = false,
}) => {
  const { getMutableFilters, updateTentativeFilters } = useExploreFilters();
  useEffect(() => {
    const helpWidget = document.querySelector<HTMLDivElement>('#jsd-widget');
    if (helpWidget) {
      helpWidget.style.display = isVisible ? 'none' : 'block';
      helpWidget.style.opacity = isVisible ? '0' : '1';
    }
  }, [isVisible]);

  const allowedPeriodValues = [
    EDateFilterValues['1YR'],
    EDateFilterValues['3YR'],
    EDateFilterValues['5YR'],
  ];
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();
  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValues.some(apv => apv === p.value),
  );
  const period = useMemo(() => {
    if (allowedPeriodValues.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  return (
    <Modal
      centered={true}
      title={
        <div className="flex justify-between">
          <div>
            <p className="text-bold text-darkest text-xl">{title}</p>
            {description && (
              <p className="pt-1 text-xs font-light text-gray-500">
                {description}
              </p>
            )}
          </div>
          {showDropdown && (
            <div className="flex items-center">
              <Dropdown
                rounded
                label="Select value"
                value={period}
                onSelect={(selectedValue: EDateFilterValues) => {
                  const filters = getMutableFilters();
                  filters.riskFilters = filters.riskFilters ?? {};
                  if (selectedValue === EDateFilterValues['1YR']) {
                    filters.riskFilters.timePeriod = MsTimePeriod.ONE_YR;
                  } else if (selectedValue === EDateFilterValues['3YR']) {
                    filters.riskFilters.timePeriod = MsTimePeriod.THREE_YRS;
                  } else if (selectedValue === EDateFilterValues['5YR']) {
                    filters.riskFilters.timePeriod = MsTimePeriod.FIVE_YRS;
                  }
                  updateTentativeFilters(filters);
                  setPeriod(parseDateValue(selectedValue), selectedValue);
                }}
                items={allowedPeriodsForDropdown}
                defaultValue={period}
              />
            </div>
          )}
        </div>
      }
      width={size || 632}
      open={isVisible}
      onCancel={toggleModal}
      className={cx(
        'explore-filters-modal [&_.ant-modal-body]:min-h-120 [&_.ant-modal-header]:mb-4',
        showDropdown && '[&_.ant-modal-header]:pt-10',
        className,
      )}
      data-test-id={toCamelCase(`modal${title}`)}
      footer={[
        <Button
          data-test-id={toCamelCase(`clear${title}`)}
          size="large"
          type="link"
          onClick={() => onClear()}
          key="secondary"
          disabled={isDisableClear}
        >
          Clear
        </Button>,
        <Button
          data-test-id={toCamelCase(`apply${title}`)}
          size="large"
          type="primary"
          className="font-semibold m-0"
          onClick={onApply}
          key="primary"
          disabled={isDisableApply}
        >
          Apply
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

export default ExploreFilterModal;
