import { ComputedAnnualisedReturn } from '@aminsights/contract';
import {
  findYtdDetail,
  isInvestmentTrust,
  MonthCode,
} from '@aminsights/shared';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

type RequiredFundProps = {
  legalStructure?: string;
  computedNavAnnualisedReturns?: ComputedAnnualisedReturn[];
  computedSharePriceAnnualisedReturns?: ComputedAnnualisedReturn[];
  totalReturn3MonthPct?: number;
  totalReturn6MonthPct?: number;
  totalReturn1YearPct?: number;
  totalReturn3YearPct?: number;
  totalReturn5YearPct?: number;
  totalReturnYtdPct?: number;
};

interface Props<T extends RequiredFundProps> {
  fund?: T;
  timePeriod?: MonthCode;
  format?: (value: number | undefined) => string;

  /**
   * @deprecated Delete this after FS is killed
   */
  showYtd?: boolean;
}

const getLegacyReturnValue = (
  fund: RequiredFundProps | undefined,
  timePeriod: MonthCode,
): number | undefined => {
  const returnMap = {
    [MonthCode.M3]: 'totalReturn3MonthPct',
    [MonthCode.M6]: 'totalReturn6MonthPct',
    [MonthCode.M12]: 'totalReturn1YearPct',
    [MonthCode.M36]: 'totalReturn3YearPct',
    [MonthCode.M60]: 'totalReturn5YearPct',
  } as const;

  if (timePeriod in returnMap) {
    const key = returnMap[timePeriod as keyof typeof returnMap];
    return fund?.[key];
  }

  return undefined;
};

const getComputedReturnValue = (
  fund: RequiredFundProps | undefined,
  timePeriod: MonthCode | 'YTD' | undefined,
): number | undefined => {
  if (!fund || !fund.legalStructure) {
    console.error('No fund or legal structure found to get computed return');
    return undefined;
  }

  const isTrust = isInvestmentTrust(fund.legalStructure);
  const computedReturns = isTrust
    ? fund.computedSharePriceAnnualisedReturns
    : fund.computedNavAnnualisedReturns;

  if (timePeriod === 'YTD') {
    return findYtdDetail(computedReturns)?.percentage;
  }

  return computedReturns?.find(
    computedReturn => computedReturn.timePeriod === timePeriod,
  )?.percentage;
};

export const RenderAnnualisedReturns = <T extends RequiredFundProps>({
  fund,
  timePeriod = MonthCode.M1,
  format,
  showYtd,
}: Props<T>) => {
  const { state } = useFeatureSwitchContext();
  const { isComputedRiskDetailsTreatmentEnabled } = state;

  const getReturnValue = (): number | undefined => {
    if (showYtd) {
      if (isComputedRiskDetailsTreatmentEnabled) {
        return getComputedReturnValue(fund, 'YTD');
      }
      return fund?.totalReturnYtdPct;
    }

    return isComputedRiskDetailsTreatmentEnabled
      ? getComputedReturnValue(fund, timePeriod)
      : getLegacyReturnValue(fund, timePeriod);
  };

  const value = getReturnValue();

  const output = format ? format(value) : roundOrEmpty(value, 1, '%');

  return output;
};
