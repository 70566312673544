import { Bucket, ResourceUserRoleEnum } from '@aminsights/contract';
import { LIMIT_FUNDS_PER_BUCKET } from '@aminsights/shared';
import { Modal } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';

import Button from '@/components/Button';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import BucketList from './BucketList';
import {
  useWatchlistModal,
  WatchlistModalContext,
  withWatchlistModal,
} from './context';

type ModalProps = {
  isins: string[];
  fundName?: string;
  size?: number;
  isVisible: boolean;
  addToWatchlist?: (isWatched: boolean) => void;
  className?: string;
  toggleModal: () => void;
  showBucketListOnly?: boolean;
  isSuccess?: (isWatched: boolean) => void;
};

interface ModalContentProps {
  onBucketSelect: (id: string, count: number) => void;
  selectedBucket: string;
  showBucketListOnly?: boolean;
  isins: string[];
  fundName?: string;
}

export const WatchlistModalContent = ({
  showBucketListOnly,
  isins,
  fundName,
  onBucketSelect,
  selectedBucket,
}: ModalContentProps) => {
  const { data } = useCurrentWatchlist();
  const bucketsWithCurrentFund = data?.buckets.filter(b =>
    isins.every(i => b.funds.map(f => f.isin).includes(i)),
  );
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;

  const bucketsWithCurrentFundIds =
    bucketsWithCurrentFund?.map(({ id }) => id) ?? [];

  const isAlreadyInBucket = (bucket: Bucket) => {
    return bucketsWithCurrentFundIds.includes(bucket.id);
  };

  return (
    <>
      <p className="text-neutral-700 text-sm font-normal">
        {!showBucketListOnly && isins.length < 0 ? (
          <>
            Select the bucket to assign{' '}
            <span className="font-semibold">'{fundName}'</span>
          </>
        ) : (
          isins.length > 0 && (
            <>
              Select the bucket to assign{' '}
              <span className="font-semibold">{isins.length}</span> funds/trust
            </>
          )
        )}
      </p>
      <p className="pt-2 text-sm font-normal text-neutral-700">
        {showBucketListOnly
          ? `Your existing buckets are listed below. If you want to add more, you may do so after selecting the funds and clicking 'Assign to Bucket'.`
          : 'To add a fund/trusts to your watchlist, you must first assign them to a bucket. You can assign a maximum of 11 funds/trusts per bucket.'}
      </p>
      <div
        className={cx(
          'mt-6 w-auto rounded-lg border overflow-hidden py-1',
          'border-[#adadb3]',
        )}
      >
        <div className="overflow-y-auto h-[280px] px-4 py-2 rounded-lg">
          {data?.buckets.map(item => {
            const fundCount = item.funds ? item.funds?.length : 0;
            const isBucketFull =
              fundCount >= LIMIT_FUNDS_PER_BUCKET ||
              fundCount + isins.length > LIMIT_FUNDS_PER_BUCKET;

            const hasUserEditAccess = item.users?.some(
              u =>
                u.id === currentUser?.sub &&
                u.role === ResourceUserRoleEnum.Editor,
            );
            const hasIsinsSelected = isins.length > 0;

            const tooltipTitle = hasIsinsSelected
              ? isAlreadyInBucket(item)
                ? 'Fund is already in this buckets'
                : isBucketFull
                ? 'Max. 11 funds per bucket'
                : !hasUserEditAccess
                ? 'You have view only access to this bucket'
                : ''
              : 'No fund selected';

            return (
              <BucketList
                key={`$bucket-list-${item.id}`}
                id={item.id}
                bucketName={item.name || ''}
                tooltipTitle={tooltipTitle}
                fundCount={item.funds ? item.funds?.length : 0}
                isBucketSelected={selectedBucket === item.id}
                isBucketDisabled={
                  isAlreadyInBucket(item) || isBucketFull || !hasUserEditAccess
                }
                onBucketSelected={() => {
                  onBucketSelect(
                    item.id || '',
                    item.funds ? item.funds?.length : 0,
                  );
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export const generateWatchlistModalFooter = ({
  assignDisabled,
  onAssign,
  onAddNewBucket,
  primaryBtnText,
  secondaryBtnText,
}: {
  onAddNewBucket: () => void;
  onAssign: () => void;
  assignDisabled?: boolean;
  primaryBtnText?: string;
  secondaryBtnText?: string;
}) => [
  <Button
    size="large"
    type="link"
    key="secondary"
    className="font-medium text-sm !text-neutral-700 hover:!text-primary"
    onClick={onAddNewBucket}
  >
    {secondaryBtnText || '+ Add New Bucket'}
  </Button>,
  <Button
    size="large"
    type="primary"
    className="h-10 font-medium w-[94px] disabled:!bg-neutral-300 !bg-primary m-0"
    onClick={onAssign}
    key="primary"
    disabled={assignDisabled ?? true}
  >
    {primaryBtnText || 'Assign'}
  </Button>,
];

const WatchlistModal: React.FCWithChild<ModalProps> = ({
  isins,
  fundName,
  isVisible,
  className,
  toggleModal,
  showBucketListOnly,
  isSuccess,
}) => {
  const { openNewBucketModal } = useContext(WatchlistModalContext);
  const { assign, selectBucket, selectedBucket } = useWatchlistModal({
    onSuccess: isSuccess,
    isins,
    onClose: toggleModal,
  });
  return (
    <>
      <Modal
        centered={true}
        width={720}
        open={isVisible}
        onCancel={toggleModal}
        className={cx(
          'max-sm:full-page-modal action-modal [&_.ant-modal-body]:!pt-0',
          className,
        )}
        footer={
          showBucketListOnly
            ? []
            : generateWatchlistModalFooter({
                onAddNewBucket: openNewBucketModal,
                onAssign: assign,
                assignDisabled: !selectedBucket,
              })
        }
        title={showBucketListOnly ? 'Buckets' : 'Add to watchlist'}
      >
        <WatchlistModalContent
          isins={isins}
          onBucketSelect={selectBucket}
          selectedBucket={selectedBucket}
          fundName={fundName}
          showBucketListOnly={showBucketListOnly}
        />
      </Modal>
    </>
  );
};

export default withWatchlistModal(WatchlistModal);
