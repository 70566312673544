import {
  Bucket,
  BucketRequestBody,
  ResourceUserRoleEnum,
} from '@aminsights/contract';
import { APP_ROUTE_WATCHLIST, AUTH0_NAMESPACE } from '@aminsights/shared';
import { Dropdown, Input } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SharedIcon } from '@/assets/svg/icons/icon-shared.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import {
  useDeleteBucket,
  useRemoveSharedBucket,
  useUpdateBucket,
} from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useSectors } from '@/hooks/query-hooks/sector-hooks/useSectors';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import BenchmarksDropdown from '@/partials/BenchmarksDropdown';
import ShareModalBucket from '@/partials/ManageBuckets/ShareBucketModal';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import NestedDrawer from '@/partials/NestedDrawer';
import SectorsDropdown from '@/partials/SectorsDropdown';
import queryClient from '@/queryClient';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

import style from '../style.module.less';
import RenderFunds from './renderFunds';
import BucketSkeleton from './Skeleton';

const BucketDefault: React.FCWithChild<{
  id: string;
  name: string;
  index: string;
  funds: Bucket['funds'];
  order: number;
  sector?: string;
  active?: boolean;
}> = props => {
  const history = useHistory();
  const { id, name, index, funds, order, active } = props;
  const { dispatch: dispatchApp } = useAppContext();
  const renameBucketRef = useRef<HTMLDivElement>(null);
  const [isBucketNameInputShown, setIsBucketNameInputShown] = useState(false);
  const [isIndexDropdownShown, setIsIndexDropdownShown] = useState(false);
  const [isSectorDropdownShown, setIsSectorDropdownShown] = useState(false);
  const [removeSharedBucketOpen, setRemoveSharedBucketOpen] = useState(false);
  const [isRemoveBucketModalOpen, setIsRemoveBucketModalOpen] = useState(false);
  const [renameBucketName, setRenameBucketName] = useState(name);
  const [isBucketNameExists, setIsBucketNameExists] = useState(false);
  const [benchmarkIndex, setBenchmarkIndex] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;
  const {
    data: benchmarkOptionsResponse,
    isLoading: isBenchmarkOptionsLoading,
  } = useBenchmarkOptions();
  const { data: sectors, isLoading: isSectorsLoading } = useSectors();

  const currentWatchlist = useCurrentWatchlist();
  const { isLoading } = currentWatchlist;
  const updateBucketMutation = useUpdateBucket();
  const deleteMutation = useDeleteBucket();
  const removeBucketMutation = useRemoveSharedBucket({
    onSuccess: async () => setRemoveSharedBucketOpen(false),
  });

  const bucketUsers =
    currentWatchlist.data?.buckets?.find(b => b.id === id)?.users || [];
  const currentBucketUser = bucketUsers.find(bu => bu.id === currentUser.sub);
  const isBucketShared = (bucketUsers?.length || 0) > 1;
  const canManageBucket =
    ResourceUserRoleEnum.Editor === currentBucketUser?.role;

  const handleRemoveNewBucketModalOpen = () => {
    setIsRemoveBucketModalOpen(prev => !prev);
  };

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  const getMenuItemsData = () => {
    const allItems = [
      {
        label: 'Rename Bucket',
        key: 'renameBucket',
        onClick: () => {
          setIsBucketNameInputShown(true);
          if (isMobile) {
            setIsNestedDrawerVisible(false);
          }
        },
        'data-test-id': 'bucketRenameButton',
      },
      {
        label: 'Change Bucket Order',
        key: 'changeBucketOrder',
        onClick: () => {
          history.push(`/${APP_ROUTE_WATCHLIST}/change-bucket-order`);
          if (isMobile) {
            setIsNestedDrawerVisible(false);
          }
        },
        'data-test-id': 'bucketChangeBucketOrderButton',
      },
      ...(Boolean(authState.decodedToken?.[`${AUTH0_NAMESPACE}/tenant_id`])
        ? [
            {
              label: 'Share',
              key: 'shareBucket-menu',
              children: [
                {
                  label: 'Share Bucket',
                  key: 'shareBucket-opt',
                  onClick: () => {
                    setIsShareModalOpen(true);
                    if (isMobile) {
                      setIsNestedDrawerVisible(false);
                    }
                  },
                },
              ],
            },
          ]
        : []),
      {
        label: <span className="danger">Delete Bucket</span>,
        key: 'deleteBucket',
        'data-test-id': 'bucketDeleteBucketButton',
        onClick: () => {
          setIsRemoveBucketModalOpen(true);
          if (isMobile) {
            setIsNestedDrawerVisible(false);
          }
        },
      },
    ];

    if (currentBucketUser?.role !== ResourceUserRoleEnum.Editor) {
      const nonOwnerActions = [
        {
          label: <span className="danger">Remove Bucket</span>,
          key: 'removeBucket',
          onClick: () => {
            setRemoveSharedBucketOpen(true);
            if (isMobile) {
              setIsNestedDrawerVisible(false);
            }
          },
          'data-test-id': 'bucketRemoveBucketButton',
        },
      ];
      if (!canManageBucket) {
        return [
          ...allItems.filter(i => i.key === 'changeBucketOrder'),
          ...nonOwnerActions,
        ];
      }
      return [...allItems, ...nonOwnerActions];
    }
    return allItems;
  };

  const bucketMenuItems = useMemo(
    () => getMenuItemsData(),
    [
      setIsBucketNameInputShown,
      setIsIndexDropdownShown,
      canManageBucket,
      setIsShareModalOpen,
      setIsRemoveBucketModalOpen,
      bucketUsers,
    ],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setRenameBucketName(e.target.value);
  };

  const updateBucketName = (bucketName = '') => {
    updateBucketMutation.mutate(
      {
        bucketId: id,
        bucket: {
          name: bucketName,
        },
      },
      {
        onSuccess: () => {
          setRenameBucketName(bucketName);
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: 'Successfully renamed bucket' },
          });
        },
        onError: () => {
          setRenameBucketName('');
          dispatchApp({
            type: APP_ACTIONS.SET_ERROR_MESSAGE,
            payload: 'Bucket not renamed',
          });
        },
      },
    );
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isBucketNameExists) {
      if (e.key === 'Enter') {
        if (renameBucketName !== name) updateBucketName(renameBucketName);
        setIsBucketNameInputShown(false);
      }
    }
  };

  useEffect(() => {
    const isBucketNameExists = currentWatchlist.data?.buckets
      ? currentWatchlist.data.buckets.some(
          b => b.name === renameBucketName && b.name !== name,
        )
      : false;

    setIsBucketNameExists(isBucketNameExists);
  }, [renameBucketName, isBucketNameInputShown]);

  useEffect(() => {
    if (!isBucketNameExists) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [renameBucketRef, isBucketNameExists, renameBucketName]);

  const handleClickOutside = () => {
    if (
      renameBucketRef.current &&
      renameBucketRef.current.id &&
      renameBucketName !== name
    ) {
      updateBucketName(renameBucketRef.current?.id);
    }
    setIsBucketNameInputShown(false);
  };

  const onDeleteClick = () => {
    return deleteMutation.mutateAsync(id);
  };

  const sectorName = useMemo(() => {
    return sectors?.find(s => s.sectorId === props.sector)?.name;
  }, [sectors, props.sector]);

  if (isLoading || isSectorsLoading) {
    return <BucketSkeleton loading={true} />;
  }

  return (
    <>
      <span className="text-xs font-medium text-neutral">
        Bucket {order + 1}
      </span>
      <div
        className={cx(
          'bg-[#F7F7F7] w-full flex flex-col p-4 rounded select-none mt-2',
          active && 'bg-info-100',
          'hover:shadow-md transition-shadow duration-300',
        )}
      >
        <div>
          <div className="flex justify-between items-center cursor-pointer">
            <div
              className="text-base font-semibold my-0 w-full text-[#181920]"
              role="button"
              onClick={() => {
                if (canManageBucket) setIsBucketNameInputShown(true);
              }}
            >
              <div
                className={cx(
                  { hidden: isBucketNameInputShown },
                  'text-darkest',
                )}
              >
                {renameBucketName || name}{' '}
                <span className="font-normal text-base text-neutral">
                  ({funds?.length || 0}){' '}
                </span>
              </div>
              {isBucketNameInputShown && (
                <div ref={renameBucketRef} id={renameBucketName}>
                  <Input
                    autoFocus
                    type="text"
                    defaultValue={name}
                    value={renameBucketName || name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                    onMouseDown={e => e.stopPropagation()}
                    onKeyDown={onKeyDown}
                    data-test-id="bucketRenameInput"
                  />
                  {isBucketNameExists && (
                    <div className="font-medium text-xs text-danger pt-1 pl-1">
                      Bucket name already exists
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={style['bucket__head-actions']}>
              {isBucketShared && (
                <SharedIcon
                  height={30}
                  width={30}
                  style={{ padding: 'unset' }}
                  onClick={
                    canManageBucket
                      ? () => setIsShareModalOpen(true)
                      : undefined
                  }
                  className={cx(canManageBucket && 'cursor-pointer')}
                />
              )}
              <Dropdown
                menu={{ items: isMobile ? [] : bucketMenuItems }}
                placement="bottomRight"
                trigger={['click']}
              >
                <div data-test-id="bucketMenuDropdown">
                  <MeatballMenu
                    width={20}
                    height={20}
                    className="!text-neutral-700"
                    onClick={() => {
                      isMobile && setIsNestedDrawerVisible(true);
                    }}
                  />
                </div>
              </Dropdown>
              {isMobile && (
                <NestedDrawer
                  menuItems={bucketMenuItems}
                  visible={isNestedDrawerVisible}
                  onClose={() => setIsNestedDrawerVisible(false)}
                  title="Select"
                  key={bucketMenuItems.length}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-2 mb-3">
            {!isIndexDropdownShown && index.length ? (
              <p
                role="button"
                onClick={() => {
                  if (canManageBucket) setIsIndexDropdownShown(true);
                }}
                className="text-sm font-medium cursor-pointer leading-5 !text-neutral-700"
              >
                {benchmarkIndex || index}
              </p>
            ) : (
              <BenchmarksDropdown
                className={style['index-dropdown']}
                placeholder={index || 'Select index'}
                value={index}
                benchmarkOptions={benchmarkOptionsResponse || []}
                onSelect={selectedValue => {
                  const indexName = benchmarkOptionsResponse?.find(
                    b => b.id === selectedValue,
                  )?.name;
                  updateBucketMutation.mutateAsync(
                    {
                      bucketId: id,
                      /**
                       * TODO: There is a type issue,
                       * BucketUpdateRequestBody does not have index but is required
                       * to work. buckets.controller.ts the updateBucket function actually
                       * expects BucketRequestBody NOT BucketUpdateRequestBody.
                       */
                      bucket: {
                        indexSecId: selectedValue,
                        index: indexName,
                      } as BucketRequestBody,
                    },
                    {
                      onSuccess: () => {
                        setBenchmarkIndex(indexName ?? '');
                        setRenameBucketName('');
                        dispatchApp({
                          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                          payload: { text: 'Successfully updated index' },
                        });
                      },
                      onError: () => {
                        setRenameBucketName('');
                        dispatchApp({
                          type: APP_ACTIONS.SET_ERROR_MESSAGE,
                          payload: 'Index not updated',
                        });
                      },
                      onSettled: () => {
                        queryClient.invalidateQueries([WATCHLIST]);
                        setIsIndexDropdownShown(false);
                      },
                    },
                  );
                }}
                onClear={() => {
                  updateBucketMutation.mutateAsync(
                    {
                      bucketId: id,
                      bucket: {
                        index: '',
                        indexSecId: '',
                      } as BucketRequestBody,
                    },
                    {
                      onSuccess: () => {
                        setBenchmarkIndex('');
                        dispatchApp({
                          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                          payload: { text: 'Successfully updated index' },
                        });
                      },
                      onError: () => {
                        dispatchApp({
                          type: APP_ACTIONS.SET_ERROR_MESSAGE,
                          payload: 'Index not updated',
                        });
                      },
                      onSettled: () => {
                        queryClient.invalidateQueries([WATCHLIST]);
                        setIsIndexDropdownShown(false);
                      },
                    },
                  );
                }}
                isOptionsLoading={isBenchmarkOptionsLoading}
                hideCustomOptions
              />
            )}
            {!isSectorDropdownShown && sectorName && (
              <p
                role="button"
                onClick={() => {
                  if (canManageBucket) setIsSectorDropdownShown(true);
                }}
                className="text-sm font-medium cursor-pointer leading-5 !text-neutral-700"
              >
                {sectorName}
              </p>
            )}
            {(isSectorDropdownShown || !sectorName) && (
              <SectorsDropdown
                value={props.sector || ''}
                options={(sectors || []).map(({ name, sectorId }) => ({
                  label: name,
                  value: sectorId,
                }))}
                isOptionsLoading={isSectorsLoading}
                isValuePlaceholder={true}
                onSelect={selectedValue => {
                  updateBucketMutation.mutateAsync(
                    {
                      bucketId: id,
                      bucket: {
                        sector: selectedValue,
                      } as BucketRequestBody,
                    },
                    {
                      onSuccess: () => {
                        dispatchApp({
                          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                          payload: { text: 'Successfully updated sector' },
                        });
                      },
                      onError: () => {
                        dispatchApp({
                          type: APP_ACTIONS.SET_ERROR_MESSAGE,
                          payload: 'Error updating sector',
                        });
                      },
                      onSettled: () => {
                        queryClient.invalidateQueries([WATCHLIST]);
                        setIsSectorDropdownShown(false);
                      },
                    },
                  );
                }}
                onClear={() => {
                  updateBucketMutation.mutateAsync(
                    {
                      bucketId: id,
                      bucket: {
                        sector: '',
                      } as BucketRequestBody,
                    },
                    {
                      onSuccess: () => {
                        dispatchApp({
                          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                          payload: { text: 'Successfully updated sector' },
                        });
                      },
                      onError: () => {
                        dispatchApp({
                          type: APP_ACTIONS.SET_ERROR_MESSAGE,
                          payload: 'Error updating sector',
                        });
                      },
                      onSettled: () => {
                        queryClient.invalidateQueries([WATCHLIST]);
                        setIsSectorDropdownShown(false);
                      },
                    },
                  );
                }}
              />
            )}
          </div>

          <div className={style.bucket__body}>
            <RenderFunds
              funds={funds || []}
              bucketId={id || ''}
              showActions={canManageBucket}
            />
          </div>
        </div>
      </div>
      <ConfirmationModalDanger
        modalInfo={{
          title: isBucketShared
            ? `Delete '${name}' for all users?`
            : `Delete '${name}'?`,
          description:
            'The funds inside this bucket will also be deleted and removed from watchlist.',
          primaryActionLabel: 'Delete',
          succesMessage: `Successfully deleted '${name}'`,
          errorMessage: `'${name}' not deleted`,
        }}
        isVisible={isRemoveBucketModalOpen}
        toggleModal={handleRemoveNewBucketModalOpen}
        onConfirm={onDeleteClick}
      />
      <ConfirmationModalDanger
        modalInfo={{
          title: `Remove '${name}'?`,
          description:
            'This shared bucket will be removed from your watchlist.',
          primaryActionLabel: 'Remove',
          succesMessage: `Successfully removed '${name}' from your watchlist.`,
          errorMessage: `'${name}' not removed.`,
        }}
        loading={removeBucketMutation.isLoading}
        isVisible={removeSharedBucketOpen}
        toggleModal={() => setRemoveSharedBucketOpen(false)}
        onConfirm={async () => removeBucketMutation.mutate(id)}
      />
      {
        <ShareModalBucket
          visible={isShareModalOpen}
          title={name}
          bucketId={id}
          onClose={() => setIsShareModalOpen(false)}
        />
      }
    </>
  );
};

export default BucketDefault;
