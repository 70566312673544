import {
  BenchmarkSource,
  ResourceUser,
  ResourceUserRoleEnum,
} from '@aminsights/contract';
import { Divider, Select } from 'antd';
import cx from 'classnames';
import { differenceWith, isEqual, uniqBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import RoleSelect from '@/partials/User/RoleSelect';
import {
  ResourceUserExtendedActions,
  ResourceUserRoleOptions,
} from '@/partials/User/types';
import UsersList from '@/partials/User/UsersList';
import { ListItemUser, sortUsersListItem } from '@/utils/user';

import { useProvidePortfolio } from '../../../useProvidePortfolio';
import ShareBenchmarkModal from '../ShareBenchmarkModal';
import styles from './style.module.less';

interface ResourceUserWithStatus extends ResourceUser {
  pendingInvite?: boolean;
}

const SharePortfolio = () => {
  const [selectedUsers, setSeletedUsers] = useState<string[]>([]);
  const [managedUsers, setManagedUsers] = useState<ResourceUserWithStatus[]>(
    [],
  );
  const [usersList, setUsersList] = useState<ListItemUser[]>([]);
  const [userRole, setUserRole] = useState<ResourceUserRoleEnum>(
    ResourceUserRoleEnum.Editor,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isShareBenchmarkModalOpen, setIsShareBenchmarkModalOpen] =
    useState(false);
  const { state, sharePortfolio } = useProvidePortfolio();
  const { dispatch: dispatchApp } = useAppContext();
  const authContext = useContext(AxiosAuthContext);
  const currentUserId = authContext.state.decodedToken?.sub;
  const { data: usersData } = useOrganizationUsers();
  const { currentPortfolioId, portfolios } = state;
  const { data: benchmarkOptions } = useBenchmarkOptions([
    BenchmarkSource.Ami,
    BenchmarkSource.Msci,
    BenchmarkSource.Morningstar,
  ]);

  const currentPortfolio = portfolios.find(
    p => p._id === state.currentPortfolioId,
  );

  const { users: portfolioUsers, invitedUsers } = currentPortfolio || {};

  const currentPortfolioUsers = [
    ...(portfolioUsers?.filter(
      u => u.id !== authContext.state.decodedToken?.sub,
    ) || []),
    ...(invitedUsers || []).map(u => ({ ...u, pendingInvite: true })),
  ];

  useEffect(() => {
    setManagedUsers(currentPortfolioUsers);
  }, [currentPortfolioUsers.length]);

  const userOptions = useMemo(() => {
    return (usersData || [])
      .filter(
        user =>
          user.user_id !== currentUserId &&
          !currentPortfolioUsers.find(bu => bu.id === user.user_id),
      )
      .map(u => ({
        label: u.name,
        value: u.user_id,
      }));
  }, [usersData?.length, currentPortfolioUsers, currentUserId]);

  const handleUpdateUsersList = useCallback(() => {
    if (usersData?.length) {
      const mappedUsers = managedUsers
        .map(portfolioUser => {
          const user = usersData.find(
            orgUser =>
              orgUser.user_id === portfolioUser.id &&
              orgUser.user_id !== currentUserId,
          );
          return (
            user && {
              id: user.user_id,
              name: user.name,
              email: user.email,
              role: portfolioUser.role,
              pendingInvite: portfolioUser.pendingInvite,
            }
          );
        })
        .filter(Boolean) as ListItemUser[];
      const sortedUsers = sortUsersListItem(mappedUsers);
      setUsersList(sortedUsers);
    }
  }, [usersData?.length, managedUsers]);

  useEffect(() => {
    handleUpdateUsersList();
  }, [usersData?.length, managedUsers?.length]);

  const handleSearchTermChange = (e: string) => {
    if (e.includes(',')) {
      setSeletedUsers(prev => [...prev, e.replace(',', '')]);
      setSearchTerm('');
      return;
    }
    setSearchTerm(e);
  };

  const handleUpdateUser = (id: string, role: ResourceUserRoleOptions) => {
    if (role === ResourceUserExtendedActions.Remove) {
      setManagedUsers(prev => prev.filter(u => u.id !== id));
      setUsersList(prev => prev.filter(u => u.id !== id));
      return;
    } else {
      setManagedUsers(prev =>
        prev.map(u => {
          if (u.id === id) {
            return { ...u, role };
          }
          return u;
        }),
      );
      setUsersList(prev =>
        prev.map(u => {
          if (u.id === id) {
            return { ...u, role };
          }
          return u;
        }),
      );
    }
  };

  const isBenchmarkShared = useMemo(() => {
    const benchmark = benchmarkOptions?.find(
      bo => bo.id === currentPortfolio?.benchmarkSetting.compositeBenchmarkId,
    );

    if (!benchmark) return true;

    return benchmark.shared;
  }, [benchmarkOptions, currentPortfolio]);

  const handleSharePortfolio = async () => {
    if (!currentPortfolioId) return;

    if (!isBenchmarkShared) {
      setIsShareBenchmarkModalOpen(true);
      return;
    }

    setIsSaving(true);

    sharePortfolio({
      portfolioId: currentPortfolioId,
      users: uniqBy(
        [
          ...selectedUsers.map(u => ({ id: u, role: userRole })),
          ...(managedUsers || []),
        ],
        'id',
      ),
    })
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully shared portfolio' },
        });
        handleUpdateUsersList();
        setSeletedUsers([]);
      })
      .catch(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error sharing portfolio',
        });
      })
      .finally(() => setIsSaving(false));
  };

  const hasManagedUsersChange = useMemo(() => {
    const difference = differenceWith(
      currentPortfolioUsers,
      managedUsers,
      isEqual,
    );

    return Boolean(difference.length);
  }, [currentPortfolioUsers, managedUsers]);

  const isPortfolioShared = Boolean(usersList?.length);
  const isEditing = Boolean(searchTerm) || Boolean(selectedUsers.length);

  return (
    <div className="flex flex-col gap-y-1">
      <h4 className={cx('text-neutral-900 font-semibold', styles['title'])}>
        Share Portfolio
      </h4>
      <p className="text-neutral-400">
        Recipients will get access to this portfolio.
      </p>
      <div>
        <div
          className={cx(
            styles['select-container'],
            isEditing && styles['select-container-active'],
          )}
        >
          <div className="flex-1 flex flex-col">
            <Select
              placement="bottomLeft"
              mode="multiple"
              data-test-id="sharePortfolioUserSelect"
              value={selectedUsers}
              onChange={val => {
                setSeletedUsers(val);
                setSearchTerm('');
              }}
              onSearch={handleSearchTermChange}
              className={styles['select-users']}
              searchValue={searchTerm}
              placeholder="Ex. John, Jennifer,..."
              options={userOptions}
              filterOption={(_, opt) => {
                return Boolean(
                  opt?.label?.toLowerCase().includes(searchTerm.toLowerCase()),
                );
              }}
              suffixIcon={<></>}
            />
            <p className="text-xs text-neutral-400 mt-1">
              Share to one or more recipients, separated by commas.
            </p>
          </div>
          <div className={styles['role-select']}>
            <RoleSelect value={userRole} onChange={setUserRole} />
          </div>
        </div>
        <div className="flex justify-end pt-3">
          <Button
            size="large"
            type="primary"
            disabled={isSaving || !selectedUsers.length}
            key="primary"
            className="w-full md:w-20 landscape:w-20"
            onClick={handleSharePortfolio}
            loading={isSaving && !!selectedUsers.length}
            data-test-id="sharePortfolioButton"
          >
            Share
          </Button>
        </div>
      </div>
      {(isPortfolioShared || hasManagedUsersChange) && (
        <>
          <Divider />
          <h4 className={cx('text-neutral-900 font-semibold')}>
            People with access{' '}
            <span className="text-neutral-400 font-medium">
              ({usersList.length} members)
            </span>
          </h4>
          <UsersList users={usersList} onUserUpdate={handleUpdateUser} />
          <div className="flex justify-end">
            <Button
              size="large"
              type="primary"
              disabled={isSaving || !hasManagedUsersChange}
              key="primary"
              className="w-20"
              onClick={handleSharePortfolio}
              loading={isSaving && hasManagedUsersChange}
            >
              Save
            </Button>
          </div>
        </>
      )}
      <ShareBenchmarkModal
        isVisible={isShareBenchmarkModalOpen}
        toggleModal={() => setIsShareBenchmarkModalOpen(prev => !prev)}
      />
    </div>
  );
};

export default SharePortfolio;
